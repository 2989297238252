import {usePlayGames} from "./usePlayGames";
import {useEffect, useState} from "react";

export const usePlayGamesWatcher = () => {
    const { getGames, getProviders, providers, groupedGames } = usePlayGames()
    const [justMounted, setJustMounted] = useState(true);

    useEffect(() => {
        if (justMounted) {
            if (groupedGames === null) {
                getGames()
            }
            if (providers === null) {
                getProviders()
            }
            setJustMounted(false)
        }
    }, [getGames, getProviders, groupedGames, justMounted, providers]);
}