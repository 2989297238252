import {FC} from "react";
import {Page} from "../components/layout/Page";
import {PageContent} from "../components/layout/PageContent";

export const TermsAndConditions: FC = () => {
    return <Page>
        <PageContent>
            <h1 ><strong>Terms &amp; conditions</strong></h1>
            <p >All uibet.org products are operated by uibet.org.</p>
            <p ><strong>1. General</strong></p>
            <p >This end user agreement (the "Agreement") should be read by you (the "User" or
                "you") in its entirety prior to your use of uibet.org's service or products. Please note that the
                Agreement constitutes a legally binding agreement between you and uibet.org.</p>
            <p >These Terms and Conditions come into force as soon as you complete the
                registration process, which includes checking the box accepting these Terms and Conditions and
                successfully creating an account. By using any part of the Website following account creation, you agree
                to these Terms and Conditions applying to the use of the Website.</p>
            <p >You must read these Terms and Conditions carefully in their entirety before
                creating an account. If you do not agree with any provision of these Terms and Conditions, you must not
                create an account or continue to use the Website.</p>
            <p >We are entitled to make amendments to these Terms and Conditions at any time and
                without advanced notice. If we make such amendments, we may take appropriate steps to bring such changes
                to your attention (such as by email or placing a notice on a prominent position on the Website, together
                with the amended terms and conditions) but it shall be your sole responsibility to check for any
                amendments, updates and/or modifications. Your continued use of uibet.org services and Website
                after any such amendment to the Terms and Conditions will be deemed as your acceptance and agreement to
                be bound by such amendments, updates and/or modifications.</p>
            <p >By clicking the "I Agree" button if and where provided and/or using the Service,
                you consent to the terms and conditions set forth in this Agreement.</p>
            <p >These Terms and Conditions may be published in several languages for
                informational purposes and ease of access by players. The English version is the only legal basis of the
                relationship between you and us and in the case of any discrepancy with respect to a translation of any
                kind, the English version of these Terms and Conditions shall prevail.</p>
            <p >&nbsp;</p>
            <p ><strong>2. Account Rules, Deposits &amp; Withdrawals</strong></p>
            <p >2.1 In order for you to be able to place bets on uibet.org, you must
                first personally register an account with us ("uibet.org Account").</p>
            <p >As part of the registration process, you will have to provide your date of
                birth, valid e-mail and choose a username and password for your login into uibet.org. You must
                enter all mandatory information requested into the registration form. It is your sole responsibility to
                ensure that the information you provide is true, complete and correct. You will have to choose a
                username which is not disruptive or offensive.</p>
            <p >It is your sole and exclusive responsibility to ensure that your login details
                are kept securely. You must not disclose your login details to anyone. We are not liable or responsible
                for any abuse or misuse of your uibet.org. Account by third parties due to your disclosure,
                whether intentional, accidental, active or passive, of your login details to any third party.</p>
            <p >Uibet.org will not accept registration from individuals:</p>
            <ul>
                <li>
                    <p >Under 18 years old or under the legal age of majority or gambling in
                        their jurisdiction;</p>
                </li>
                <li>
                    <p >Residing in jurisdictions from where it is illegal or gambling is not
                        permitted. Uibet.org is not able to verify the legality of the Service in each
                        jurisdiction and it is the User's responsibility to ensure that their use of the Service is
                        lawful.</p>
                </li>
                <li>
                    <p >Provide misleading information or try to pass by third parties.</p>
                </li>
                <li>
                    <p >Being residents of The United States of America, other U.S.
                        territories, Netherlands and France;&nbsp;</p>
                </li>
                <li>
                    <p >Working or contracted with any legal entity which is part of the same
                        group as The Company, meaning other entities being, directly or indirectly, linked with
                        Uibet.org, or with The Company​​third party suppliers;</p>
                </li>
                <li>
                    <p >Being professional punters, betting syndicates, traders, professional
                        athletes and players wagering for the sole scope to take advantage of “inside” information.</p>
                </li>
            </ul>
            <p >You are prohibited from selling, transferring or acquiring Player Accounts to or
                from other Players.</p>
            <p >2.2 In order for the Client to request withdrawals, remove all initial
                limitations and have full rights in the promotions, the account must be verified. For the account
                verification The Company will request the following details:</p>
            <ul>
                <li>
                    <p >Client’s ID for age verification (players under eighteen (18) years of
                        age are not accepted).</p>
                </li>
                <li>
                    <p >Client’s place of residence (utility bill indicating the declared land
                        line phone number).</p>
                </li>
                <li>
                    <p >Client’s valid email address (cannot be the same as any existing
                        Client’s email address).</p>
                </li>
                <li>
                    <p >Client’s proof of ownership of the payment method used for funds
                        deposit in The Company.</p>
                </li>
            </ul>
            <p >In case the Client has changed place of residence, telephone number or any other
                element of his data, he/she is obliged to immediately inform the company, so the new data can be
                updated.</p>
            <p >2.3​ An individual applying for an Account acknowledges and accepts:</p>
            <ul>
                <li>
                    <p >The “T&amp;C” as currently published on the website, as well as any
                        possible future changes&nbsp;</p>
                </li>
                <li>
                    <p >Any regulatory provisions or other decisions made from time to time by
                        the Governing Authorities;&nbsp;</p>
                </li>
                <li>
                    <p >The Company customer​​support is available in several local languages.
                        In any case of a dispute between a Client and the Company, only the English version shall
                        prevail and be used.</p>
                </li>
            </ul>
            <p >2.4 ​The Company reserves the right, at its own discretion, at all times,
                to:</p>
            <ul>
                <li>
                    <p >Decline to open an Account and/or to close an existing Account,
                        without any explanation whatsoever;&nbsp;</p>
                </li>
                <li>
                    <p >Decline to accept or limit deposits without any explanation
                        whatsoever;&nbsp;</p>
                </li>
                <li>
                    <p >Suspend Client’s account and/or cancel the participation of a Client
                        in promotional activities, competitions or other services, whenever The Company is of the
                        opinion that there are legitimate concerns that an account is, has been or may be used for
                        illegal, fraudulent or dishonest practices.&nbsp;</p>
                </li>
                <li>
                    <p >Hold and manage funds belonging to Clients in accordance with
                        generally accepted guidelines for cash management regarding such funds; this may include a
                        Payment Service Provider being entrusted to hold funds in the name of and/or for the benefit of
                        the Client;&nbsp;</p>
                </li>
                <li>
                    <p >Suspend and/or cancel the participation of the Client in the Services,
                        and/or forfeit and/or confiscate funds available on his/her Client Account if the Client is
                        found cheating, or if it is determined by the Company that the Client has employed or made use
                        of a system (including machines, robots, computers, software or any other automated system)
                        designed or suitable to defeat the Client Application, and/or the software used to offer the
                        Services&nbsp;</p>
                </li>
                <li>
                    <p >Hold or charge any Payment method fees or expenses, from the Client’s
                        account, for all previous deposits and withdrawals, plus any administrative or legal costs the
                        Client provoked for any reason, whenever The Company​​is of the opinion that there are
                        legitimate concerns that an Account is, has been or may be used for illegal, fraudulent or
                        dishonest practices.</p>
                </li>
                <li>
                    <p >In case of a possible refund to the client in regards to the above
                        mentioned cases, the maximum refund is limited to the last deposit minus the deposit and
                        withdrawal fees.</p>
                </li>
            </ul>
            <p >2.5​ The company accepts no responsibility for any Betting Duties or taxes
                arisen by the local authorities of the client’s territory. Such duties or taxes are paid exclusively by
                the client. If the client refuses to pay such amounts, the company has no liability whosoever.</p>
            <p >2.6​ You are allowed to have only one uibet.org Account. If you attempt
                to open more than one uibet.org Account, all of your accounts may be blocked, suspended or closed
                and any crypto amount credited to your account/s will be frozen.</p>
            <p >If you notice that you have more than one registered uibet.org Account
                you must notify us immediately. Failure to do so may lead to your uibet.org Account being
                blocked.</p>
            <p >You will inform us as soon as you become aware of any errors with respect to
                your account or any calculations with respect to any bet you have placed. We reserve the right to
                declare null and void any bets that are subject to such an error.</p>
            <p >2.7 ​Any legal claim or dispute arising under or in connection with the Contract
                shall be settled in accordance with the Governing Law of the Place of the Contract. In the event of a
                dispute, it is advisable that the Account Holder lodges a complaint with customer service using the
                contact details in the Website. The parties should do their utmost to reach an amicable settlement
                within a reasonable time. The client may also lodge a complaint within 6 months to
                ​www.curacao-egaming.com​ or using the e-mail address complaints@curacao-egaming.com​.​The complaint
                must contain clear and unequivocal information about the complainant's identity and shall give all the
                relevant details that gave rise to the complaint.</p>
            <p >2.8​ The Company Website works by using cookies for the following purposes:</p>
            <ul>
                <li>
                    <p >To identify the Client's preferred language so it is automatically
                        selected when the Client returns to Website;&nbsp;</p>
                </li>
                <li>
                    <p >To ensure that bets placed by the Client are associated with the
                        Client's betting coupon and account;&nbsp;</p>
                </li>
                <li>
                    <p >To ensure that the Client receives any bonuses the Client is eligible
                        for;</p>
                </li>
                <li>
                    <p >For analysis of Website traffic so as to allow us to make suitable
                        improvements. Please be aware that it is not possible to use the Website without cookies.</p>
                </li>
            </ul>
            <p >2.9​ The Company shall not accept a wager from a Client unless an Account has
                been established in the name of the Client and there are adequate funds in the Account to cover the
                amount of the wager; or funds necessary to cover the amount of the wager are provided in an approved
                way.</p>
            <p >2.10 All​ winnings will be credited to the Client’s Account. Withdrawals from
                the Account, to the extent of the original deposit amount, are made using the same payment method as was
                used for the original deposit. For instance, if a deposit was made with a credit card, at least an
                amount from the winnings corresponding to the original deposit amount will be withdrawn to the same
                credit card whereas the remaining winnings amount may be withdrawn by using any other payment method
                (subject to the company’s sole discretion). Withdrawals will only be made to a credit card, a bank
                account or e-wallet account held in the Client’s own name. Nevertheless, The Company reserves the right
                to use an alternative payment method for withdrawal at its sole discretion.</p>
            <p >2.11​ When a Client wishes to withdraw funds he had deposited on his Account,
                but has not wagered them in any game or sport betting offer, at least one time for the amount of the
                initial deposit, The Company reserves the right to refuse to transfer the funds to another bank account
                than the one the funds originated from, if The Company suspects a fraud and/or money laundering.</p>
            <p >2.12​ The Company reserves the right to charge for withdrawals for
                administrative cost plus any commission due to third parties, as indicated on the Website, or
                exceptionally to clients may not use their funds for wagering.</p>
            <p >2.13​ In case of a withdrawal request, we reserve the right to define minimum or
                maximum withdrawal amounts for each client. In such a case, the client will receive his/her winnings in
                up to ten (10) equal monthly installments.</p>
            <p >2.14​ Clients acting on behalf of third parties or aiming for a professional
                wagering, price arbitrage or with the sole purpose of trading affiliate commission may have their
                withdrawals partially or totally declined, especially if their wagering pattern has the aim to harm
                financially the company.&nbsp;</p>
            <p >&nbsp;</p>
            <p ><strong>3. Standard Promotional Terms</strong></p>
            <p >3.1 ​The terms contained in this document (Standard Promotional Terms) apply to
                all promotional offers available through the Website (each a Promotion) and, together with any
                applicable specific promotional terms, which may also be referred to as "Key Terms", set out on the
                individual web landing page for the relevant Promotion (Specific Promotional Terms), form a legal
                agreement between you and The Company and can only be amended with our consent. By participating in any
                Promotion, you are indicating that you accept and agree to be bound by the Rules.</p>
            <p >3.2 ​In the event of any conflict or inconsistency between these Standard
                Promotional Terms and the Specific Promotional Terms, the Specific Promotional Terms shall prevail,
                followed by these Standard Promotional Terms, but only to the extent necessary to resolve such conflict
                or inconsistency.</p>
            <p >3.3 ​Unless otherwise indicated, participation in each Promotion is limited to
                one per person, family, household, address or organization to whom The Company sends the Promotion. The
                Company reserves the right to restrict participation in certain Promotions to players who fulfill
                specific selection criteria. Where a Promotion permits multiple participants, The Company reserves the
                right, in its sole discretion, to limit the number of participants.</p>
            <p >3.4​ In the event that you withdraw funds in respect of which you have received
                a Bonus, without having met any applicable release requirements, cash out restrictions or general
                eligibility criteria, you shall forfeit the entire sum of the Bonus and any winnings resulting from this
                Bonus and The Company shall be entitled to deduct this from your Account.</p>
            <p >3.5​ Your use of any real money bonuses is subject to The Company review for
                irregular playing patterns. To ensure fair gaming and the proper use of bonuses, The Company considers
                low margin betting, equal betting, zero risk bets or hedge betting to be irregular gaming when deployed
                to exploit bonuses. Further examples of irregular playing patterns also include, but are not limited to:
                (i) placing single or multiple bets of a value of fifty percent or more of the bonus on any single game,
                individual hand, or round, building a balance and significantly changing play patterns (bet size, game
                types, bet structures etc.) in order to meet the bonus release requirement; (ii) placing large bets
                which result in a substantial gain followed by a drop in bet size equal to or more than seventy five
                percent of the previous average bet size until the bonus release requirements have been met; (iii) if we
                have good grounds to suspect that you have sought only to exploit a bonus offered by The Company in good
                faith to enhance your entertainment (for example, on acceptance of a bonus, the minimum wagering
                requirement is met and funds are subsequently withdrawn). In the event that The Company deems that an
                irregular playing pattern has taken place, The Company reserves the right to prevent you from cashing
                out Account funds and/or withhold any of your winnings derived from your use of the bonus.&nbsp;</p>
            <p >&nbsp;</p>
            <ol>
                <li>
                    <p ><strong>Sports Rules&nbsp; ​</strong></p>
                </li>
            </ol>
            <p >4​.1 General Betting Rules​</p>
            <p >4​.1.1 The​ outcome of a market is settled once this is determined. If a market
                is not determined during the game, the company settles the result of the game once the normal duration
                (regular time) of the match is concluded, unless stated otherwise on market description on the website.
                Example 1: Match winner 1X2 market of an event is determined after the end of the normal duration
                (regular time) of the event. Football (Soccer) 1x2 Match Winner is determined after the 90 min,
                including any extra minutes called by the referee of the match as “normal duration” In case an event is
                driven to Overtime (when regular time does not decide the winner) all markets clearly state that are
                subject to “include overtime” are paid after the completion of the Overtime. Any penalty kicks (or other
                decider) may follow, is not taken in consideration, unless clearly stated in the market. Events not
                started on time for any reason or even postponed may be kept open and all bets remain valid if those
                kick off (start) within the next 48 hours from the official start time. In any other case, the company
                reserves, at its own discretion, the right to void all bets of such postponed events and refund the
                stakes to the customers. Events abandoned after its starting time and get continued from the organizer
                within 48 hours from the official start time, the company reserves the right to keep all bets valid and
                settle according to this result. Events abandoned after its starting time and not get continued from the
                organizer within 48 hours, the company will settle all determined markets decided in the pitch and void
                the rest by refunding stakes to customers. However in cases like that, the company reserves, at its own
                discretion, the right to void all bets of such abandoned events and refund the stakes to the customers.
                In the above general rule there are exceptions as in: Tennis games will be kept open with all bets valid
                until the officials or organizing body declares a winner. In such a case, the 48 hours rule is not
                valid. However and in case of a player retirement, all markets determined in the pitch are settled
                accordingly and all the rest declared void and null. For avoidance of doubt if a tennis player retired
                before the last point concluded, the match winner market is void, but all markets related to specific
                sets or games that are determined, are settled accordingly. If a match is decided by a Match tie-break
                then it will be considered to be the 3rd set. Every tie-break or Match tie-break counts as 1 game.
                Baseball games when two teams are playing 2 games in a row are considered as Game 1 and Game 2 and will
                be settled as chronologically played with all bets valid. For avoidance of doubt if Team A plays vs Team
                B twice, the result of Game 1 will count for bets offered on Game 1 as originally scheduled and the
                result of Game 2 will count for bets offered on Game 2, as originally scheduled. The 48 hours rule is
                valid. (e.g. if a football match is abandoned in the second half, the markets of the first half will be
                settled normally). (e.g. in the example above, the markets of the second half have not decided) will be
                void and stakes will be refunded to customers</p>
            <p >4.1.2​ The company reserves the right not to accept all or part of a bet without
                providing a reason to the customer. If a bet is not accepted, customer’s stakes are refunded</p>
            <p >4.1.3 ​Before an event starts, the company reserves, at its own discretion, the
                right to void or cancel part or whole of a bet even after its acceptance without providing any reason to
                the customer. After the event start, the company reserves, at its own discretion, the right to void or
                cancel part or whole of a bet, even after its acceptance if there is a valid reason for this, such
                as:</p>
            <ul>
                <li>
                    <p >mistake in the wording (palpable error) of the event or the odds, or
                        starting time.&nbsp;</p>
                </li>
                <li>
                    <p >the customer attempts to bypass company limits (potential payout) and
                        risk management by placing multiple identical or similar bets or opening multiple accounts the
                        customer is getting advantage of any public announcement or secret information he has access to
                        that determines the outcome of the bet.&nbsp;</p>
                </li>
                <li>
                    <p >the customer combined related bets.&nbsp;</p>
                </li>
                <li>
                    <p >the customer is actively participating in the event, such as Players,
                        Referees, Managers or has direct or indirect relationship with events participants.</p>
                </li>
                <li>
                    <p >the company suffers a technical error offering wrong odds or
                        events.&nbsp;</p>
                </li>
                <li>
                    <p >any other valid reason duly communicated to the customer upon
                        request.</p>
                </li>
            </ul>
            <p >4.1.4​ Our company reserves the right to cancel all bets of an event, if there
                is any change regarding the venue of the event.</p>
            <p >4.1.5​ Our company reserves the right to cancel all bets if there are radical
                changes in the circumstances of an event such as the length of playing time, the distance in a race
                speed or the number of periods, etc.</p>
            <p >4.2 Explanation of specific Betting Types​</p>
            <p >Scorers: First, Last, Anytime Scorer Bets apply to regular time only.</p>
            <p >Own goals (based on official authority) omitted or count as “no Scorer” if the
                game has only “own goals”.</p>
            <p >Betting First Goalscorer will be considered lost if a player never plays or
                comes in the pitch after the first goal of the game has been scored, except in the case of an own goal,
                which is ignored. Bets on Last Goalscorer will be considered lost if a player never plays.</p>
            <p >Player to score apply for any player, whatever the time he entered the game.
                Bets on Anytime Goalscorer, apply to any player involved in the game, regardless of the time they enter
                the game. Bets on Anytime goalscorer will be considered lost if a player never plays.</p>
            <p >In case a match is abandoned but there has been at least one Scorer, bets on the
                first Goalscorer apply and bets on the last goalscorer are void. Anytime Goalscorer market for this
                player is settled as winner and bets on other players are void.</p>
            <p >Head 2 Head Bets Predict which of the participants will occupy the highest
                position or will have better results or more points. In order for these bets to stand both participants
                must actively participate in the event. In case the two participants achieve the exact same winning
                result and therefore achieve a tie, all bets are void.</p>
            <p >Outright Bets Predict which of the participants (3 or more) will occupy the
                highest position or will have better results or more points. In order for these bets to stand all
                participants must actively participate in the event. If one or more participants will not participate in
                the event, the odds on the rest participants are recalculated and settled with a reduction in the odds,
                by applying the probability of the missing participants to the rest participants applying the same
                margin. If two or more participants achieve exact same winning result and therefore achieve a tie, the
                applicable odds will be equal to the accepted odds divided by the number of tie participants (eg with
                initial odds 2.70 and three tie participants the final odds will be: 2.70 / 3 = 0.90), unless the
                official governing body chooses only one winner with an additional statistic criterion (e.g.
                assists).</p>
            <p >Booking markets A yellow card counts as one card. A red card counts as two
                cards. If a player gets one yellow plus a second yellow driving to a red card, this counts overall as
                three cards. As a result, a player cannot receive more than three cards. Settlement will be based on all
                available card data that appear during the normal playing time of an event .Cards shown after the final
                whistle will not be taken under consideration. Cards in non-playing players (players that have already
                been replaced, coaches, players on the bench who have not taken part in the game) are not taken under
                consideration. Booking markets with points A yellow card counts as 10 points and the red and the second
                yellow-red as 25. The second yellow for a player that leads to a red card is not counted. As a result, a
                player cannot receive more than 35 points. Settlement will be based on all available card data that
                appear during the normal playing time of an event .Cards shown after the final whistle not be taken
                under consideration. Cards in non-playing players (players that have already been replaced, coaches,
                players on the bench who have not taken part in the game) are not taken under consideration.</p>
            <p >Corners A corner gets counted for the relevant market settlement only in the
                case it has been executed. Corners won by the teams but not executed do not count for market settlement.
                Intervals First interval is considered the time period from 00:01 until the completion of the minute
                indicated as the end minute. For avoidance of doubt the interval 1-15 min is considered the period from
                00:01 to 15:00 Last interval of a time period is considered from the minute indicated until the end of
                the period as decided by the referee. For avoidance of doubt the interval 30-45 min is considered the
                period from 30:01 until the end of First Half. The interval 75-90 min is considered the period from
                75:01 to the end of the Full Time.</p>
            <p >4.3​ A bet is not valid until it is validated and shown in the customer bet
                history. In cases of uncertainty about the validity of a bet, the customer is requested to check the
                open (pending) bets, or contact Customer Service.</p>
            <p >4.4​ The Company assumes no liability for correctness, completeness or
                up-to-dateness of the information services provided i.e. live scores and result messages displayed on
                the website or sent via email. The stake is exclusively the amount confirmed and recorded by the
                company. In case the erroneous event/market is canceled all bets are void and the odds are set to
                1,00.</p>
            <p >4.5 ​The Company only accepts those results which have been achieved on the
                playing field or platform. Results amounting from disciplinary measures from a sports' court or another
                court of law will not be accepted. In case of violation of sports laws, the company reserves the right
                to freeze any winnings and withhold payment(s). This also applies for live betting events.</p>
            <p >4.5.1 Winnings​ are paid out up to a maximum limit. If the Client places a bet
                and its payment exceeds the maximum winning limit, The Company is not liable for the excess of the
                amount. In such cases the payment of the winnings will be reduced correspondingly. The maximum winnings
                amount per betslip is set to EUR 25.000 or equivalent to other currencies. This also applies if The
                Company has not warned the bettor against possible excesses of the winning limits when placing the bet.
                The maximum winning limit is EUR 20.000 or equivalent per Client and per Week. The Company reserves the
                right to determine different winning limits for individual players.</p>
            <p >4.6 ​In order to avoid a bet not being accepted due to odds changes, the system
                automatically updates the odds before slip confirmation and the customer should check the latest odds in
                the betting slip before proceeding to the confirmation.</p>
            <p >4.7​ The Client may not combine related bets on the same event (“related bets”
                are two or more different bets that have related contingency).</p>
            <p >4.8 ​The Company offers the option of Cash-Out before an event full time result.
                If the Cash-Out request is successful, this will be displayed and the bet will be settled immediately.
                The actual final result of the related market will have no impact on the Cash Out amount. The Cash Out
                amount offered at any time is the total amount that will be returned to your account if successful. Any
                bets that are settled using Cash Out will not count towards the turnover requirement of any offer. If a
                bet is placed with the intention of using Cash-Out, The Company cannot guarantee that the Cash-Out
                feature will be always available. The Company reserves the right to reverse the settlement of a Cash Out
                if the bet or a market is settled in error.</p>
            <p >&nbsp;</p>
            <p ><strong>5. Casino, Live Casino &amp; Games General Rules</strong></p>
            <p >5.1 Casino &amp; Games</p>
            <p >5.1.1 All​ bets placed on the Casino are placed respectively on the servers of
                3rd party providers depending on the provider of the game in which the bet is placed. The Company is
                licensed by the Casino Games Providers to represent, promote, and market the Casino games. The Company​
                receives,​ holds and pays funds in relation to an Account Holder for the purpose of transactions and
                settlements with the CASINO.</p>
            <p >5.1.2 ​All Casino games are highly trustworthy and verified by authorities to
                prevent any potential hacking action. However technology loopholes not related to our company may give
                the ability to specific users to take advantage of such loopholes. Irregular winnings or betting
                patterns will be investigated and The Company reserves the right to confiscate any winnings made under
                such a pattern.</p>
            <p >5.1.3 ​Casino games are running with a low margin on clients’ benefit. In
                addition to that, bonuses may be awarded and further reduce the margin of the company. If bonus rules
                are misspelled or omitted by any client who takes advantage acting in a fraudulent attitude alone or as
                a syndicate, The Company reserves the right to confiscate any winnings made under such a pattern.</p>
            <p >5.1.4​ You hereby agree that in case you win a jackpot of EUR 5,000 or higher
                (or the equivalent to any other currency) in relation to any casino game offered in The Company, the
                winnings may be awarded once the Casino provider verifies and accepts it.</p>
            <p >5.1.5​ The Company has insured Jackpots with the Casino providers therefore all
                winnings are paid through the global pools. For the avoidance of any misunderstanding, the Client will
                receive the winnings once the casino provider has transferred the relevant funds to The Company.</p>
            <p >5.1.6 The following territories are restricted for NetEnt games:</p>
            <p >1. Absolute Restriction</p>
            <p >United States of America, Canada, United Kingdom, Spain, France and Italy.</p>
            <p >2. Blacklisted Territories</p>
            <p >NetEnt content shall not be served in the following territories:</p>
            <p >Afghanistan, Albania, Algeria, Angola, Australia, Bahamas, Botswana, Cambodia,
                Ecuador, Ethiopia, Ghana, Guyana, Hong Kong, Iran, Iraq, Israel, Kuwait, Laos, Myanmar, Namibia,
                Nicaragua, North Korea, Pakistan, Panama, Papua New Guinea, Philippines, Singapore, Sri Lanka, Sudan,
                Syria, Taiwan, Trinidad and Tobago, Tunisia, Uganda, Yemen, Zimbabwe.</p>
            <p >3. Regulated Territories (only permitted to offer if receipt of a license from
                the local regulator which has been seen and approved by NetEnt)</p>
            <p >Belgium, Bulgaria, Canada, Czech Republic, Denmark, Estonia, France, Italy,
                Latvia, Lithuania, Mexico, Portugal, Romania, Serbia, Spain, Sweden, United Kingdom, United States of
                America.</p>
            <p >4. Branded Game Territories</p>
            <p >Jumanji, emojiplanet, Guns &amp; Roses, Jimi Hendrix &amp; Motörhead, Planet of
                the Apes cannot be offered in the following countries:</p>
            <p >Afghanistan, Albania, Algeria, Angola, Australia, Bahamas, Botswana, Cambodia,
                Ecuador, Ethiopia, Ghana, Guyana, Hong Kong, Iran, Iraq, Israel, Kuwait, Laos, Myanmar, Namibia,
                Nicaragua, North Korea, Pakistan, Panama, Papua New Guinea, Philippines, Singapore, Sri Lanka, Sudan,
                Syria, Taiwan, Trinidad and Tobago, Tunisia, Uganda, Yemen, Zimbabwe. Belgium, Bulgaria, Canada, Czech
                Republic, Denmark, Estonia, France, Italy, Latvia, Lithuania, Mexico, Portugal, Romania, Serbia, Spain,
                Sweden, United States of America, United Kingdom, Azerbaijan, China, India, Malaysia, Qatar, Russia,
                Thailand, Turkey and Ukraine.</p>
            <p >Vikings Video Slot must not be offer in the following jurisdictions:</p>
            <p >&nbsp;Afghanistan, Albania, Algeria, Angola, Australia, Azerbaijan, Cambodia,
                China, Ecuador, France, Guyana, Hong Kong, India, Indonesia, Iran, Iraq, Israel, Kuwait, Laos, Malaysia,
                Myanmar, Namibia, North Korea, Pakistan, Papua New Guinea, Philippines, Qatar, Russia, Singapore, South
                Korea, Sudan, Syria, Taiwan, Thailand, Tunisia, Turkey, Ukraine, United States of America, Uganda.</p>
            <p >Universal Monsters (Frankenstein, the Bride of Frankenstein, Dracula, The Mummy,
                The Wolf Man, Creature from the Black Lagoon and The Invisible Man), can only be played in the following
                countries:</p>
            <p >Andorra, Armenia, Azerbaijan, Belarus, Bosnia and Herzegovina, Brazil, Georgia,
                Iceland, Liechtenstein, Moldova, Monaco, Montenegro, Norway, Russia, San Marino, Serbia, Switzerland,
                Ukraine, Croatia, Macedonia, Turkey, Austria, Bulgaria, Cyprus, Czech Republic, Finland, France,
                Germany, Greece, Hungary, Ireland, Latvia, Lithuania, Luxembourg, Malta, Netherlands, Peru, Poland,
                Slovakia, Slovenia, and Sweden.&nbsp;</p>
            <p ><strong>5.2 Live Casino Rules</strong></p>
            <p >5.2.1 All​ bets placed on the Live Casino are placed respectively on the servers
                of the above mentioned providers depending on the provider of the game in which the bet is placed. The
                Company is authorized by the above providers to represent, promote and market the services of the Live
                Casino and to receive, hold and pay funds in relation to an Account Holder for the purpose of
                transactions and settlements with the Live Casino.</p>
            <p >These Terms and Conditions in a part, which relates to your participation in the
                Games, shall be governed by the Laws of Curaçao, and in a part which relates to payment collection and
                transactions shall be governed by the Laws of UK.</p>
        </PageContent>
    </Page>
}