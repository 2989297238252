import { Page } from "../components/layout/Page"
import {PageContent} from "../components/layout/PageContent";
import {useLanguage} from "../features/localisation/useLanguage";

export const AboutUsPage = () => {
    const { languagePack: { langShort }} = useLanguage()

    return <Page>
        <PageContent>
            {(() => {
                switch (langShort) {
                    case 'en':
                        return <>
                            <h1>About us</h1>
                            <p>uibet.org is your portal to a world of sports entertainment, live action, excitement and
                                fun.</p>
                            <p>Our goal is to provide the best gambling experience for our customers through ensuring a
                                fun, safe and secure casino environment.</p>
                            <p>We're proud to be part of the gambling community and provide you with the safest
                                experience out there. This is why we take great care in providing our customers with
                                secure and hassle-free gaming. With the support of our team, we ensure that all of your
                                data is safe and sound.</p>
                        </>
                    case 'fr':
                        return <>
                            <h1>À propos de nous</h1>
                            <p>uibet.org est votre portail vers un monde de divertissement sportif, d'action en direct,
                                d'excitation et de plaisir.</p>
                            <p>Notre objectif est de fournir la meilleure expérience de jeu à nos clients en assurant un
                                environnement de casino amusant, sûr et sécurisé.</p>
                            <p>Nous sommes fiers de faire partie de la communauté des jeux d'argent et de vous offrir
                                l'expérience la plus sûre qui soit. C'est pourquoi nous prenons grand soin de fournir à
                                nos clients un jeu sécurisé et sans tracas. Avec le soutien de notre équipe, nous
                                veillons à ce que toutes vos données soient protégées et en sécurité.</p>
                        </>
                    case 'ar':
                        return <>
                            <h1>من نحن</h1>
                            <p>uibet.org هو بوابتك إلى عالم من الترفيه الرياضي، الإثارة الحية، المتعة والتشويق.</p>
                            <p>هدفنا هو تقديم أفضل تجربة لعب لعملائنا من خلال ضمان بيئة كازينو ممتعة وآمنة ومحمية.</p>
                            <p>نحن فخورون بأن نكون جزءًا من مجتمع الألعاب ونوفر لك تجربة الأكثر أمانًا. لهذا السبب نهتم
                                كثيرًا بتوفير تجربة لعب آمنة وخالية من المتاعب لعملائنا. بدعم من فريقنا، نضمن أن جميع
                                بياناتك آمنة ومحمية.</p>
                        </>
                }
            })()}

        </PageContent>
    </Page>
}