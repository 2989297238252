import {SubCategoryItem} from "./SubCategoryItem";
import {websiteRoutes} from "../../../features/routes/routes";
import Lobby from '../../../../assets/subcategory_icons/lobby.svg'
import Featured from '../../../../assets/subcategory_icons/featured.svg'
import New from '../../../../assets/subcategory_icons/new.svg'
import Slots from '../../../../assets/subcategory_icons/slots.svg'
import Crash from '../../../../assets/subcategory_icons/crash.svg'
import Pragmatic from '../../../../assets/subcategory_icons/pragmatic.svg'
import Providers from '../../../../assets/subcategory_icons/providers.svg'
import LiveCasino from '../../../../assets/subcategory_icons/live_casino.svg'
import GameShows from '../../../../assets/subcategory_icons/game_shows.svg'
import Roulette from '../../../../assets/subcategory_icons/roulette.svg'
import Blackjack from '../../../../assets/subcategory_icons/blackjack.svg'
import TableGames from '../../../../assets/subcategory_icons/table_games.svg'
import Other from '../../../../assets/subcategory_icons/other.svg'
import {useLocation} from "react-router-dom";
import {useLanguage} from "../../../features/localisation/useLanguage";


export const Items = () => {
    const { languagePack: {pack: {subcategories: lang}}} = useLanguage()
    const location = useLocation();

    const checkRoute = (route: string): boolean => {
        const currentPath = location.pathname;
        const currentSearch = location.search;

        // Разделяем роут на путь и параметры
        const [path, queryString] = route.split('?');
        const queries = queryString ? queryString.split('&') : [];

        // Проверяем совпадение пути
        if (currentPath !== path) {
            return false;
        }

        // Проверяем наличие параметров
        if (queries.length === 0) {
            // Если роут не содержит параметров, проверяем, что и адресная строка не содержит параметров
            return currentSearch === '';
        }

        // Проверяем наличие параметров в браузерной строке
        const currentQueries = currentSearch.slice(1).split('&');
        return queries.every(query => currentQueries.includes(query));
    };

    return <>
        {/*<SubCategoryItem*/}
        {/*    title={'Lobby'}*/}
        {/*    link={websiteRoutes.casino}*/}
        {/*    picUrl={Lobby}*/}
        {/*    active={checkRoute(websiteRoutes.casino)}*/}
        {/*/>*/}
        {/*<SubCategoryItem*/}
        {/*    title={'Featured'}*/}
        {/*    link={websiteRoutes.featured}*/}
        {/*    picUrl={Featured}*/}
        {/*    active={checkRoute(websiteRoutes.featured)}*/}
        {/*/>*/}
        {/*<SubCategoryItem*/}
        {/*    title={'New'}*/}
        {/*    link={websiteRoutes.new}*/}
        {/*    picUrl={New}*/}
        {/*    active={checkRoute(websiteRoutes.new)}*/}
        {/*/>*/}
        {/*<SubCategoryItem*/}
        {/*    title={'Slots'}*/}
        {/*    link={websiteRoutes.slots}*/}
        {/*    picUrl={Slots}*/}
        {/*    active={checkRoute(websiteRoutes.slots)}*/}
        {/*/>*/}
        {/*<SubCategoryItem*/}
        {/*    title={'Crash'}*/}
        {/*    link={websiteRoutes.crash}*/}
        {/*    picUrl={Crash}*/}
        {/*    active={checkRoute(websiteRoutes.crash)}*/}
        {/*/>*/}
        <SubCategoryItem
            title={lang.pragmatic}
            link={websiteRoutes.pragmatic}
            picUrl={Pragmatic}
            active={checkRoute(websiteRoutes.pragmatic)}
        />
        <SubCategoryItem
            title={lang.providers}
            link={websiteRoutes.providers}
            picUrl={Providers}
            active={checkRoute(websiteRoutes.providers)}
        />
        {/*<SubCategoryItem*/}
        {/*    title={'Live Casino'}*/}
        {/*    link={websiteRoutes.liveCasino}*/}
        {/*    picUrl={LiveCasino}*/}
        {/*    active={checkRoute(websiteRoutes.liveCasino)}*/}
        {/*/>*/}
        {/*<SubCategoryItem*/}
        {/*    title={'Game Shows'}*/}
        {/*    link={websiteRoutes.gameShows}*/}
        {/*    picUrl={GameShows}*/}
        {/*    active={checkRoute(websiteRoutes.gameShows)}*/}
        {/*/>*/}
        {/*<SubCategoryItem*/}
        {/*    title={'Roulette'}*/}
        {/*    link={websiteRoutes.roulette}*/}
        {/*    picUrl={Roulette}*/}
        {/*    active={checkRoute(websiteRoutes.roulette)}*/}
        {/*/>*/}
        {/*<SubCategoryItem*/}
        {/*    title={'Blackjack'}*/}
        {/*    link={websiteRoutes.blackjack}*/}
        {/*    picUrl={Blackjack}*/}
        {/*    active={checkRoute(websiteRoutes.blackjack)}*/}
        {/*/>*/}
        {/*<SubCategoryItem*/}
        {/*    title={'Table Games'}*/}
        {/*    link={websiteRoutes.tableGames}*/}
        {/*    picUrl={TableGames}*/}
        {/*    active={checkRoute(websiteRoutes.tableGames)}*/}
        {/*/>*/}
        {/*<SubCategoryItem*/}
        {/*    title={'Other'}*/}
        {/*    link={websiteRoutes.otherGames}*/}
        {/*    picUrl={Other}*/}
        {/*    active={checkRoute(websiteRoutes.otherGames)}*/}
        {/*/>*/}
    </>
}