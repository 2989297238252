import React, {FC} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Box, Stack} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useLanguageSwitcherLogic} from "./LanguageSwitcher.hook";

export const LanguageSwitcherMobile: FC = () => {
    const {switchLanguage, filteredLangs, currentLang} = useLanguageSwitcherLogic()

    return <Accordion
        sx={{
            backgroundColor: 'black',
            border: '1px solid white',
            borderRadius: '8px !important',
            color: 'white',
            '&:before': {
                display: 'none',
            },
        }}
    >
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
                padding: '8px',
                minHeight: 0,
                '& .MuiAccordionSummary-content': {
                    margin: '0',
                },
                '&.Mui-expanded': {
                    minHeight: 0,
                },
            }}
        >
            <Box
                key={currentLang?.langShort}
                display={'flex'}
                alignItems={'center'}
                gap={1}
            >
                <Box
                    width={30}
                    height={30}
                    borderRadius={'50%'}
                    sx={{
                        "& img": {
                            width: "100%",
                            height: "100%",
                        },
                    }}
                >
                    <img
                        src={currentLang?.langIconUrl}
                        alt={currentLang?.langName}
                        aria-label={`Switch to ${currentLang?.langName}`}
                    />
                </Box>

                {currentLang?.langName}
            </Box>
        </AccordionSummary>
        <AccordionDetails
            sx={{
                padding: '8px', // Уменьшаем отступы
            }}
        >
            <Stack
                gap={1}
            >
                {filteredLangs.map(lang => <Box
                    key={lang.langShort}
                    display={'flex'}
                    alignItems={'center'}
                    gap={1}
                    onClick={() => switchLanguage(lang.lang)}
                    sx={{cursor: 'pointer'}}
                >
                    <Box
                        width={30}
                        height={30}
                        borderRadius={'50%'}
                        sx={{
                            "& img": {
                                width: "100%",
                                height: "100%",
                            },
                        }}
                    >
                        <img
                            src={lang.langIconUrl}
                            alt={lang.langName}
                            aria-label={`Switch to ${lang.langName}`}
                        />
                    </Box>

                    {lang.langName}
                </Box>)}
            </Stack>
        </AccordionDetails>
    </Accordion>
}