import { LangPack } from "./langModel";
import { Lang } from "./useLanguageInterface";
import { enLang } from "./packs/enLang";
import { frLang } from "./packs/frLang";
import {arLang} from "./packs/arLang";

export const getLangPackByName = (lang: Lang): LangPack => {
    switch (lang) {
        case Lang.AR:
            return arLang
        case Lang.FR:
            return frLang
        case Lang.EN:
        default:
            return enLang
    }
}