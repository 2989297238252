import { configureStore } from "@reduxjs/toolkit"
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {ErrorSlice} from "./features/ApiErrors/errorSlice";
import {LanguageSlice} from "./features/localisation/LanguageSlice";
import {UserSlice} from "./features/user/userSlice";
import {PlayGamesSlice} from "./features/play_games/playGamesSlice";
import {TransactionsSlice} from "./features/transactions/transactionsSlice";
import {GameHistorySlice} from "./features/gameHistory/gameHistorySlice";

const store = configureStore({
    reducer: {
        errors: ErrorSlice.reducer,
        lang: LanguageSlice.reducer,
        user: UserSlice.reducer,
        playGames: PlayGamesSlice.reducer,
        transactions: TransactionsSlice.reducer,
        gamesHistory: GameHistorySlice.reducer,
    }
})

export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector