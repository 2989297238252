import React, {useState} from "react";
import {
    IconButton,
    Popover,
    Box,
} from "@mui/material";
import {Lang} from "../../features/localisation/useLanguageInterface";
import {useLanguageSwitcherLogic} from "./LanguageSwitcher.hook";



const LanguageSwitcher: React.FC= () => {
    const {switchLanguage, filteredLangs, currentLang} = useLanguageSwitcherLogic()

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLanguageSelect = (code: Lang) => {
        switchLanguage(code);
        handleClose();
    };

    const isOpen = Boolean(anchorEl);

    return (
        <Box sx={{ display: "inline-block" }}>
            {/* Кнопка для текущего языка */}
            <IconButton
                onClick={handleOpen}
                sx={{
                    width: 24,
                    height: 24,
                    borderRadius: "50%",
                    overflow: "hidden",
                    padding: 0,
                    backgroundColor: "transparent",
                    "& img": {
                        width: "100%",
                        height: "100%",
                    },
                }}
            >
                <img
                    src={currentLang?.langIconUrl}
                    alt={currentLang?.langName}
                    aria-label={`Current language: ${currentLang?.langName}`}
                />
            </IconButton>

            <Popover
                open={isOpen}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                slotProps={{
                    paper: {
                        sx: {
                            borderRadius: '24px',
                            padding: 0,
                            ml: '4px',
                            mt: '8px'
                        },
                    },
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                    }}
                >
                    {filteredLangs
                        .map((lang) => (
                            <IconButton
                                key={lang.lang}
                                onClick={() => handleLanguageSelect(lang.lang)}
                                sx={{
                                    width: 30,
                                    height: 30,
                                    borderRadius: "50%",
                                    overflow: "hidden",
                                    padding: 0,
                                    backgroundColor: "transparent",
                                    transition: "border-color 0.2s",
                                    border: "2px solid transparent",
                                    "&:hover": {
                                        borderColor: "white",
                                    },
                                    "& img": {
                                        width: "100%",
                                        height: "100%",
                                    },
                                }}
                            >
                                <img
                                    src={lang.langIconUrl}
                                    alt={lang.langName}
                                    aria-label={`Switch to ${lang.langName}`}
                                />
                            </IconButton>
                        ))}
                </Box>
            </Popover>
        </Box>
    );
};

export default LanguageSwitcher;
