import React, {FC, useMemo, useState} from "react";
import {CasinoGridView} from "./CasinoGridView";
import ProvidersIcon from "../../../../assets/subcategory_icons/providers.svg";
import {Box, Button, LinearProgress, Stack, Typography, useTheme} from "@mui/material";
import {websiteRoutes} from "../../../features/routes/routes";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {usePlayGames} from "../../../features/play_games/usePlayGames";
import {useCommonMediaQueries} from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {getMediaCDN} from "../../../config";
import {ProviderCard} from "./ProviderCard";
import {useLanguage} from "../../../features/localisation/useLanguage";

export const ProvidersGridExpanded: FC = () => {
    const { languagePack: {pack: {casino: lang, common: langCommon, subcategories: langSub}, direction}} = useLanguage()
    const theme = useTheme()
    const {providers} = usePlayGames()
    const [cutCount, setCutCount] = useState<number>(32)
    const { mediumScreen: md} = useCommonMediaQueries()
    const gap = theme.spacing(1)
    const itemSize = md ? `calc((100% - ${gap} * (3 - 1)) / 3)` : `calc((100% - ${gap} * (8 - 1)) / 8)`
    const cdn = getMediaCDN()

    const cuttedProvidersList = useMemo(() => {
        return providers !== null ?providers.slice(0, cutCount) : []
    }, [cutCount, providers])

    const progressPercentage = providers !== null ? (cuttedProvidersList.length / providers.length) * 100 : 0;

    return <CasinoGridView
        picUrl={ProvidersIcon}
        title={langSub.providers}
    >
        <Box
            py={3}
            sx={{
                overflowX: 'auto',
            }}
        >
            <Box
                // width={!md ? `calc(840px + ${gap} * 7)` : `calc(315px + ${gap} * 2)`}
                display={'flex'}
                gap={gap}
                flexWrap={'wrap'}
            >
                {cuttedProvidersList.map(provider => {
                    return <ProviderCard
                        providerLink={`${websiteRoutes.casino}?provider=${provider.provider_name}`}
                        id={provider.provider_name}
                        itemSize={itemSize}
                        md={false}
                        // picUrl={`${cdn}/provider_img/hor/${provider.provider_name}.png`}
                        picUrl={`${cdn}/provider_img/hor/${provider.logo}`}
                    />
                })}
            </Box>
        </Box>

        {providers !== null && cuttedProvidersList.length < providers.length && <Stack
            gap={3}
            alignItems={'center'}
        >
            <Button
                onClick={() => setCutCount(cutCount + 16)}
                variant={'outlined'}
                size={'large'}
                endIcon={direction === 'ltr' ? <ArrowForwardIcon/> : <ArrowBackIcon/>}
            >
                {langCommon.loadMore}
            </Button>
            <Typography>
                {lang.showedOf
                    .replace('%%a%%', `<strong>${cuttedProvidersList.length}</strong>`)
                    .replace('%%b%%', `<strong>${providers.length}</strong>`)
                    .split(/(<strong>.*?<\/strong>)/)
                    .map((part, index) =>
                        part.startsWith('<strong>') && part.endsWith('</strong>') ? (
                            <strong key={index}>
                                {part.replace('<strong>', '').replace('</strong>', '')}
                            </strong>
                        ) : (
                            part
                        )
                    )}
            </Typography>
            <LinearProgress
                value={progressPercentage}
                sx={{
                    width: '100%',
                    height: '8px',
                    borderRadius: 5,
                    backgroundColor: 'black',
                    '& .MuiLinearProgress-bar': {
                        borderRadius: 5,
                        backgroundColor: 'white',
                    },
                }}
                variant={'determinate'}
            />
        </Stack>}
    </CasinoGridView>
}