export const PrivacyPolicyPageEn = () => {
    return <>
        <h1><strong>Privacy policy</strong></h1>
        <p>This privacy notice for Uibet.org ("Company," "we," "us," or "our"),
            describes how and why we might collect, store, use, and/or share ("process") your information when
            you
            use our services ("Services"), such as when you:</p>
        <ul>
            <li>
                <p>Visit our website at www.Uibet.org, that links to this privacy
                    notice</p>
            </li>
            <li>
                <p>Engage with us in other related ways, including any sales, marketing,
                    or events</p>
            </li>
        </ul>
        <p>Questions or concerns? Reading this privacy notice will help you understand your
            privacy rights and choices. If you do not agree with our policies and practices, please do not use
            our
            Services. If you still have any questions or concerns, please contact us at <a
                href="mailto:support@uibet.com">support@Uibet.org</a></p>
        <p>SUMMARY OF KEY POINTS</p>
        <p>This summary provides key points from our privacy notice.</p>
        <p>What personal information do we process? When you visit, use, or navigate our
            Services, we may process personal information depending on how you interact with Uibet.org and
            the Services, the choices you make, and the products and features you use.</p>
        <p>Do we process any sensitive personal information? We may process sensitive
            personal information when necessary with your consent or as otherwise permitted by applicable
            law.</p>
        <p>Do we receive any information from third parties? We do not receive any
            information from third parties.</p>
        <p>How do we process your information? We process your information to provide,
            improve, and administer our Services, communicate with you, for security and fraud prevention, and
            to
            comply with law. We may also process your information for other purposes with your consent. We
            process
            your information only when we have a valid legal reason to do so.</p>
        <p>In what situations and with which types of parties do we share personal
            information? We may share information in specific situations and with specific categories of third
            parties.</p>
        <p>How do we keep your information safe? We have organizational and technical
            processes and procedures in place to protect your personal information. However, no electronic
            transmission over the internet or information storage technology can be guaranteed to be 100%
            secure, so
            we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties
            will
            not be able to defeat our security and improperly collect, access, steal, or modify your
            information.&nbsp;</p>
        <p>What are your rights? Depending on where you are located geographically, the
            applicable privacy law may mean you have certain rights regarding your personal
            information.&nbsp;</p>
        <p>How do you exercise your rights? The easiest way to exercise your rights is by
            contacting us. We will consider and act upon any request in accordance with applicable data
            protection
            laws.</p>
        <p>&nbsp;</p>
        <p>TABLE OF CONTENTS</p>
        <ol>
            <li>
                <p>WHAT INFORMATION DO WE COLLECT?</p>
            </li>
            <li>
                <p>HOW DO WE PROCESS YOUR INFORMATION?</p>
            </li>
            <li>
                <p>WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL
                    INFORMATION?</p>
            </li>
            <li>
                <p>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</p>
            </li>
            <li>
                <p>WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</p>
            </li>
            <li>
                <p>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</p>
            </li>
            <li>
                <p>HOW LONG DO WE KEEP YOUR INFORMATION?</p>
            </li>
            <li>
                <p>HOW DO WE KEEP YOUR INFORMATION SAFE?</p>
            </li>
            <li>
                <p>DO WE COLLECT INFORMATION FROM MINORS?</p>
            </li>
            <li>
                <p>WHAT ARE YOUR PRIVACY RIGHTS?</p>
            </li>
            <li>
                <p>CONTROLS FOR DO-NOT-TRACK FEATURES</p>
            </li>
            <li>
                <p>DO WE MAKE UPDATES TO THIS NOTICE?</p>
            </li>
            <li>
                <p>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</p>
            </li>
            <li>
                <p>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                    YOU?</p>
            </li>
        </ol>
        <p>&nbsp;</p>
        <ol>
            <li>
                <p>WHAT INFORMATION DO WE COLLECT?</p>
            </li>
        </ol>
        <p>Personal information you disclose to us</p>
        <p>In Short: We collect personal information that you provide to us.</p>
        <p>We collect personal information that you voluntarily provide to us when you
            register on the Services, express an interest in obtaining information about us or our products and
            Services, when you participate in activities on the Services, or otherwise when you contact us.</p>
        <p>Personal Information Provided by You. The personal information that we collect
            depends on the context of your interactions with us and the Services, the choices you make, and the
            products and features you use. The personal information we collect may include the following:</p>
        <ul>
            <li>
                <p>names</p>
            </li>
            <li>
                <p>phone numbers</p>
            </li>
            <li>
                <p>email addresses</p>
            </li>
            <li>
                <p>mailing addresses</p>
            </li>
            <li>
                <p>usernames</p>
            </li>
            <li>
                <p>passwords</p>
            </li>
            <li>
                <p>debit/credit card numbers</p>
            </li>
        </ul>
        <p>Sensitive Information. When necessary, with your consent or as otherwise
            permitted by applicable law, we process the following categories of sensitive information:</p>
        <ul>
            <li>
                <p>information revealing race or ethnic origin</p>
            </li>
        </ul>
        <p>All personal information that you provide to us must be true, complete, and
            accurate, and you must notify us of any changes to such personal information.</p>
        <p>Information automatically collected</p>
        <p>In Short: Some information — such as your Internet Protocol (IP) address and/or
            browser and device characteristics — is collected automatically when you visit our Services.</p>
        <p>We automatically collect certain information when you visit, use, or navigate
            the Services. This information does not reveal your specific identity (like your name or contact
            information) but may include device and usage information, such as your IP address, browser and
            device
            characteristics, operating system, language preferences, referring URLs, device name, country,
            location,
            information about how and when you use our Services, and other technical information. This
            information
            is primarily needed to maintain the security and operation of our Services, and for our internal
            analytics and reporting purposes.</p>
        <p>Like many businesses, we also collect information through cookies and similar
            technologies.</p>
        <p>The information we collect includes:</p>
        <ul>
            <li>
                <p>Log and Usage Data. Log and usage data is service-related, diagnostic,
                    usage, and performance information our servers automatically collect when you access or use
                    our
                    Services and which we record in log files. Depending on how you interact with us, this log
                    data
                    may include your IP address, device information, browser type, and settings and information
                    about your activity in the Services (such as the date/time stamps associated with your
                    usage,
                    pages and files viewed, searches, and other actions you take such as which features you
                    use),
                    device event information (such as system activity, error reports (sometimes called "crash
                    dumps"), and hardware settings).</p>
            </li>
            <li>
                <p>Device Data. We collect device data such as information about your
                    computer, phone, tablet, or other device you use to access the Services. Depending on the
                    device
                    used, this device data may include information such as your IP address (or proxy server),
                    device
                    and application identification numbers, location, browser type, hardware model, Internet
                    service
                    provider and/or mobile carrier, operating system, and system configuration information.</p>
            </li>
            <li>
                <p>Location Data. We collect location data such as information about your
                    device's location, which can be either precise or imprecise. How much information we collect
                    depends on the type and settings of the device you use to access the Services. For example,
                    we
                    may use GPS and other technologies to collect geolocation data that tells us your current
                    location (based on your IP address). You can opt out of allowing us to collect this
                    information
                    either by refusing access to the information or by disabling your Location setting on your
                    device. However, if you choose to opt out, you may not be able to use certain aspects of the
                    Services.</p>
            </li>
        </ul>
        <p>&nbsp;</p>
        <ol>
            <li>
                <p>HOW DO WE PROCESS YOUR INFORMATION?</p>
            </li>
        </ol>
        <p>In Short: We process your information to provide, improve, and administer our
            Services, communicate with you, for security and fraud prevention, and to comply with law. We may
            also
            process your information for other purposes with your consent.</p>
        <p>We process your personal information for a variety of reasons, depending on how
            you interact with our Services, including:</p>
        <ul>
            <li>
                <p>To facilitate account creation and authentication and otherwise manage
                    user accounts. We may process your information so you can create and log in to your account,
                    as
                    well as keep your account in working order.</p>
            </li>
            <li>
                <p>To request feedback. We may process your information when necessary to
                    request feedback and to contact you about your use of our Services.</p>
            </li>
            <li>
                <p>To send you marketing and promotional communications. We may process
                    the personal information you send to us for our marketing purposes, if this is in accordance
                    with your marketing preferences. You can opt out of our marketing emails at any time. For
                    more
                    information, see "WHAT ARE YOUR PRIVACY RIGHTS?" below).</p>
            </li>
            <li>
                <p>To deliver targeted advertising to you. We may process your
                    information to develop and display personalized content and advertising tailored to your
                    interests, location, and more.</p>
            </li>
            <li>
                <p>To protect our Services. We may process your information as part of
                    our efforts to keep our Services safe and secure, including fraud monitoring and
                    prevention.</p>
            </li>
            <li>
                <p>To identify usage trends. We may process information about how you use
                    our Services to better understand how they are being used so we can improve them.</p>
            </li>
            <li>
                <p>To determine the effectiveness of our marketing and promotional
                    campaigns. We may process your information to better understand how to provide marketing and
                    promotional campaigns that are most relevant to you.</p>
            </li>
            <li>
                <p>To save or protect an individual's vital interest. We may process your
                    information when necessary to save or protect an individual’s vital interest, such as to
                    prevent
                    harm.</p>
            </li>
        </ul>
        <p>&nbsp;</p>
        <ol>
            <li>
                <p>WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</p>
            </li>
        </ol>
        <p>In Short: We only process your personal information when we believe it is
            necessary and we have a valid legal reason (i.e., legal basis) to do so under applicable law, like
            with
            your consent, to comply with laws, to provide you with services to enter into or fulfill our
            contractual
            obligations, to protect your rights, or to fulfill our legitimate business interests.</p>
        <p>If you are located in the EU or UK, this section applies to you.</p>
        <p>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain
            the valid legal bases we rely on in order to process your personal information. As such, we may rely
            on
            the following legal bases to process your personal information:</p>
        <ul>
            <li>
                <p>Consent. We may process your information if you have given us
                    permission (i.e., consent) to use your personal information for a specific purpose. You can
                    withdraw your consent at any time.</p>
            </li>
            <li>
                <p>Legitimate Interests. We may process your information when we believe
                    it is reasonably necessary to achieve our legitimate business interests and those interests
                    do
                    not outweigh your interests and fundamental rights and freedoms. For example, we may process
                    your personal information for some of the purposes described in order to:</p>
            </li>
            <li>
                <p>Send users information about special offers and discounts on our
                    products and services</p>
            </li>
            <li>
                <p>Develop and display personalized and relevant advertising content for
                    our users</p>
            </li>
            <li>
                <p>Analyze how our services are used so we can improve them to engage and
                    retain users</p>
            </li>
            <li>
                <p>Support our marketing activities</p>
            </li>
            <li>
                <p>Diagnose problems and/or prevent fraudulent activities</p>
            </li>
            <li>
                <p>Understand how our users use our products and services so we can
                    improve user experience</p>
            </li>
            <li>
                <p>Legal Obligations. We may process your information where we believe it
                    is necessary for compliance with our legal obligations, such as to cooperate with a law
                    enforcement body or regulatory agency, exercise or defend our legal rights, or disclose your
                    information as evidence in litigation in which we are involved.</p>
            </li>
            <li>
                <p>Vital Interests. We may process your information where we believe it
                    is necessary to protect your vital interests or the vital interests of a third party, such
                    as
                    situations involving potential threats to the safety of any person.</p>
            </li>
        </ul>
        <p>&nbsp;</p>
        <ol>
            <li>
                <p>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</p>
            </li>
        </ol>
        <p>In Short: We may share information in specific situations described in this
            section and/or with the following categories of third parties.</p>
        <p>Vendors, Consultants, and Other Third-Party Service Providers. We may share your
            data with third-party vendors, service providers, contractors, or agents ("third parties") who
            perform
            services for us or on our behalf and require access to such information to do that work. The
            categories
            of third parties we may share personal information with are as follows:</p>
        <ul>
            <li>
                <p>Ad Networks</p>
            </li>
        </ul>
        <p>We also may need to share your personal information in the following
            situations:</p>
        <ul>
            <li>
                <p>Business Transfers. We may share or transfer your information in
                    connection with, or during negotiations of, any merger, sale of company assets, financing,
                    or
                    acquisition of all or a portion of our business to another company.</p>
            </li>
        </ul>
        <p>&nbsp;</p>
        <ol>
            <li>
                <p>WHAT IS OUR STANCE ON THIRD-PARTY WEBSITES?</p>
            </li>
        </ol>
        <p>In Short: We are not responsible for the safety of any information that you
            share with third parties that we may link to or who advertise on our Services, but are not
            affiliated
            with, our Services.</p>
        <p>The Services may link to third-party websites, online services, or mobile
            applications and/or contain advertisements from third parties that are not affiliated with us and
            which
            may link to other websites, services, or applications. Accordingly, we do not make any guarantee
            regarding any such third parties, and we will not be liable for any loss or damage caused by the use
            of
            such third-party websites, services, or applications. The inclusion of a link towards a third-party
            website, service, or application does not imply an endorsement by us. We cannot guarantee the safety
            and
            privacy of data you provide to any third parties. Any data collected by third parties is not covered
            by
            this privacy notice. We are not responsible for the content or privacy and security practices and
            policies of any third parties, including other websites, services, or applications that may be
            linked to
            or from the Services. You should review the policies of such third parties and contact them directly
            to
            respond to your questions.</p>
        <p>&nbsp;</p>
        <ol>
            <li>
                <p>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</p>
            </li>
        </ol>
        <p>In Short: We may use cookies and other tracking technologies to collect and
            store your information.</p>
        <p>We may use cookies and similar tracking technologies (like web beacons and
            pixels) to access or store information. Specific information about how we use such technologies and
            how
            you can refuse certain cookies is set out in our Cookie Notice.</p>
        <p>&nbsp;</p>
        <ol>
            <li>
                <p>HOW LONG DO WE KEEP YOUR INFORMATION?</p>
            </li>
        </ol>
        <p>In Short: We keep your information for as long as necessary to fulfill the
            purposes outlined in this privacy notice unless otherwise required by law.</p>
        <p>We will only keep your personal information for as long as it is necessary for
            the purposes set out in this privacy notice, unless a longer retention period is required or
            permitted
            by law (such as tax, accounting, or other legal requirements). No purpose in this notice will
            require us
            keeping your personal information for longer than the period of time in which users have an account
            with
            us.</p>
        <p>When we have no ongoing legitimate business need to process your personal
            information, we will either delete or anonymize such information, or, if this is not possible (for
            example, because your personal information has been stored in backup archives), then we will
            securely
            store your personal information and isolate it from any further processing until deletion is
            possible.</p>
        <p>&nbsp;</p>
        <ol>
            <li>
                <p>HOW DO WE KEEP YOUR INFORMATION SAFE?</p>
            </li>
        </ol>
        <p>In Short: We aim to protect your personal information through a system of
            organizational and technical security measures.</p>
        <p>We have implemented appropriate and reasonable technical and organizational
            security measures designed to protect the security of any personal information we process. However,
            despite our safeguards and efforts to secure your information, no electronic transmission over the
            Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise
            or
            guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to
            defeat
            our security and improperly collect, access, steal, or modify your information. Although we will do
            our
            best to protect your personal information, transmission of personal information to and from our
            Services
            is at your own risk. You should only access the Services within a secure environment.</p>
        <p>&nbsp;</p>
        <ol>
            <li>
                <p>DO WE COLLECT INFORMATION FROM MINORS?</p>
            </li>
        </ol>
        <p>In Short: We do not knowingly collect data from or market to children under 18
            years of age.</p>
        <p>We do not knowingly solicit data from or market to children under 18 years of
            age. By using the Services, you represent that you are at least 18 or that you are the parent or
            guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that
            personal information from users less than 18 years of age has been collected, we will deactivate the
            account and take reasonable measures to promptly delete such data from our records. If you become
            aware
            of any data we may have collected from children under age 18, please contact us at <a
                href="mailto:support@uibet.com">support@Uibet.org</a></p>
        <p>&nbsp;</p>
        <ol>
            <li>
                <p>CONTROLS FOR DO-NOT-TRACK FEATURES</p>
            </li>
        </ol>
        <p>Most web browsers and some mobile operating systems and mobile applications
            include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference
            not
            to have data about your online browsing activities monitored and collected. At this stage no uniform
            technology standard for recognizing and implementing DNT signals has been finalized. As such, we do
            not
            currently respond to DNT browser signals or any other mechanism that automatically communicates your
            choice not to be tracked online. If a standard for online tracking is adopted that we must follow in
            the
            future, we will inform you about that practice in a revised version of this privacy notice.</p>
        <p>&nbsp;</p>
        <ol>
            <li>
                <p>DO WE MAKE UPDATES TO THIS NOTICE?</p>
            </li>
        </ol>
        <p>In Short: Yes, we will update this notice as necessary to stay compliant with
            relevant laws.</p>
        <p>We may update this privacy notice from time to time. The updated version will be
            indicated by an updated "Revised" date and the updated version will be effective as soon as it is
            accessible. If we make material changes to this privacy notice, we may notify you either by
            prominently
            posting a notice of such changes or by directly sending you a notification. We encourage you to
            review
            this privacy notice frequently to be informed of how we are protecting your information.</p>
        <p>&nbsp;</p>
        <ol>
            <li>
                <p>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</p>
            </li>
        </ol>
        <p>If you have questions or comments about this notice, you may email us at <a
            href="mailto:support@uibet.com">support@Uibet.org</a></p>
        <p>&nbsp;</p>
        <ol>
            <li>
                <p>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                    YOU?</p>
            </li>
        </ol>
        <p>Based on the applicable laws of your country, you may have the right to request
            access to the personal information we collect from you, change that information, or delete it. To
            request to review, update, or delete your personal information, please contact us at <a
                href="mailto:support@uibet.com">support@Uibet.org</a></p>
    </>
}