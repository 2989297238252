import {SearchLogicHook} from "./SearchGameModal.interface";
import {useMemo, useState} from "react";
import {usePlayGames} from "../../features/play_games/usePlayGames";

export const useSearchLogic = () : SearchLogicHook => {
    const [ searchValue, setSearchValue ] = useState ('')
    const { providers, games } = usePlayGames()
    
    const foundProviders = useMemo (() => {
        if (searchValue.length < 3) return []
       if (providers === null) return []
       return providers.filter (provider => provider.title?.toLowerCase ().includes (searchValue.toLowerCase ()))
    }, [providers, searchValue])

    const foundGames = useMemo (() => {
        if (searchValue.length < 3) return []
       if (games === null) return []
       return games.filter (game => game.Name.toLowerCase ().includes (searchValue.toLowerCase ()))
    }, [games, searchValue])

    console.log(foundGames)

    return {
        foundProviders,
        foundGames,
        setSearchValue,
        searchValue
    }
}