import {FC} from "react";
import {Box, Button, useTheme} from "@mui/material";
import {CasinoGridProps} from "./CasinoGrid.interface";
import {Link} from "react-router-dom";
import {getMediaCDN} from "../../../config";
import {useCommonMediaQueries} from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {useFilterGames} from "./CasinoGrid.funcs";
import {GameCard} from "./GameCard";
import {CasinoGridView} from "./CasinoGridView";
import {websiteRoutes} from "../../../features/routes/routes";
import {useLanguage} from "../../../features/localisation/useLanguage";
import {common} from "@mui/material/colors";

export const CasinoGrid: FC<CasinoGridProps> = ({picUrl, title, viewAllLink, mode, provider, gameType }) => {
    const theme = useTheme()
    const { languagePack: {pack: {common: lang}}} = useLanguage()

    const gap = theme.spacing(1)
    const itemSize = `calc((100% - ${gap} * (8 - 1)) / 8)`
    const cdn = getMediaCDN()
    const { mediumScreen: md} = useCommonMediaQueries()

    const filteredGames = useFilterGames(mode, gameType, provider)

    return <CasinoGridView
        picUrl={picUrl}
        title={title}
        actions={<>
            <Link to={viewAllLink}>
            <Button variant={'outlined'} size={'large'}>
                {lang.viewAllBTN}
            </Button>
        </Link>
        </>}
    >
        <Box
            sx={{
                overflowX: 'auto',
            }}
        >
            <Box
                width={md ? `calc(840px + ${gap} * 7)` : undefined}
                display={'flex'}
                gap={gap}
                flexWrap={'wrap'}
            >
                {filteredGames.slice(0, 16).map(game => {
                    return <GameCard
                        gameLink={`${websiteRoutes.gameLaunch}?internalId=${game.internal_id}`}
                        id={game.Name}
                        key={game.internal_id}
                        itemSize={itemSize}
                        md={md}
                        picUrl={`url(${cdn}/game_img/sqr_mid/${game.Pic})`}
                    />
                })}
            </Box>
        </Box>
    </CasinoGridView>
}