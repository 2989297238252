import React, {FC} from "react";
import {Box, Button, Stack, Typography, useTheme} from "@mui/material";
import {CasinoGridViewProps} from "./CasinoGrid.interface";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export const CasinoGridView: FC<CasinoGridViewProps> = ({title, picUrl, children, actions, backButtonClick}) => {
    const theme = useTheme()

    return <Stack
        borderRadius={'8px'}
        p={2}
        sx={{
            backgroundColor: '#e2e2e2',
            color: theme.palette.background.default
        }}
        gap={1}
    >
        <Box
            display={'flex'}
            justifyContent={'space-between'}
        >
            <Box display={'flex'} gap={1} alignItems={'center'}>
                {backButtonClick && <Button
                    onClick={backButtonClick}
                    variant={'outlined'}
                    sx={{
                        height: '34px',
                        maxWidth: '34px',
                        minWidth: '34px',
                        px: '6px',
                        justifyContent:'center',
                        display: 'flex'
                    }}
                >
                    <ArrowBackIosIcon fontSize={'small'} sx={{mr: '-6px'}}/>
                </Button>}
                {picUrl && <Box
                    width={'24px'}
                    height={'24px'}
                    sx={{
                        backgroundImage: `url(${picUrl})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                    }}
                />}
                <Typography fontSize={24}>
                    {title}
                </Typography>
            </Box>

            <Box display={'flex'} gap={1} alignItems={'center'}>
                {actions}
            </Box>
        </Box>

        {children}
    </Stack>
}