import {Box, Typography, useTheme} from "@mui/material";
import {FC} from "react";
import {NavigatorTopItemProps} from "./NavigatorTopItem.interface";
import {Link} from "react-router-dom";

export const NavigatorTopItem: FC<NavigatorTopItemProps> = ({title, link, active = false}) => {
    const theme = useTheme()

    return <Box
        sx={theme => ({
            '& a': {
                textDecoration: 'none',
                color: theme.palette.text.primary },
        })}
    >
        <Link to={link}>
            <Box
                sx={{
                    color: active ? theme.palette.text.secondary : undefined,
                    transition: 'color 0.4s ease-in-out',
                    '&:hover': {
                        color: theme.palette.text.secondary
                    }
                }}
                p={1.5}
                borderBottom={active ? `2px solid ${theme.palette.text.secondary}` : undefined}
                minHeight={'50px'}
                display={'flex'}
                alignItems={'center'}
            >
                <Typography fontSize={'16px'}>
                    {title.toUpperCase()}
                </Typography>
            </Box>
        </Link>
    </Box>
}