import {FC} from "react";
import {Box} from "@mui/material";
import {NavigatorBottomItem} from "./NavigatorBottomItem";
import Casino from '../../../../assets/navigator_bottom_icons/Casino.svg'
import User from '../../../../assets/navigator_bottom_icons/User.svg'
import LiveCasino from '../../../../assets/navigator_bottom_icons/Live_Casino.svg'
import Home from '../../../../assets/navigator_bottom_icons/Home.svg'
import Sports from '../../../../assets/navigator_bottom_icons/Sports.svg'
import Transactions from '../../../../assets/navigator_bottom_icons/Transaction.svg'
import GameHistory from '../../../../assets/navigator_bottom_icons/GameHistory.svg'
// import MenuIcon from '@mui/icons-material/Menu';
import {websiteRoutes} from "../../../features/routes/routes";
import {useLocation} from "react-router-dom";
import {getNormalizedPath} from "../../../features/common_funcs/urlUtils";
import {useLanguage} from "../../../features/localisation/useLanguage";


export const NavigatorBottom: FC<{dashboardMode?: boolean}> = ({dashboardMode = false}) => {
    const { languagePack: {pack: {navigation: lang}}} = useLanguage()
    const location = useLocation();

    const isActivePath = (paths: string | string[]): boolean => {
        const normalizedPaths = getNormalizedPath(paths)
        return normalizedPaths.includes(location.pathname.replace(/\/$/, ''));
    };

    return <Box
        display={'flex'}
        height={'100%'}
    >
        {!dashboardMode ? <>
            <NavigatorBottomItem
                iconUrl={Casino}
                title={lang.casino}
                link={websiteRoutes.casino}
                active={isActivePath(websiteRoutes.casino)}
            />
            <NavigatorBottomItem
                iconUrl={LiveCasino}
                title={lang.liveCasino}
                link={websiteRoutes.liveCasino}
                active={isActivePath(websiteRoutes.liveCasino)}
            />
            <NavigatorBottomItem
                iconUrl={Home}
                title={lang.home}
                link={websiteRoutes.home}
                active={isActivePath(websiteRoutes.home)}
            />
            <NavigatorBottomItem
                iconUrl={Sports}
                title={lang.sports}
                link={websiteRoutes.sports}
                active={isActivePath(websiteRoutes.sports)}
            />
        </>
        :
        <>
            <NavigatorBottomItem
                iconUrl={User}
                title={lang.userInfo}
                link={websiteRoutes.userInfo}
                active={isActivePath(websiteRoutes.userInfo)}
            />
            <NavigatorBottomItem
                iconUrl={Transactions}
                title={lang.transactions}
                link={websiteRoutes.transactions}
                active={isActivePath(websiteRoutes.transactions)}
            />
            <NavigatorBottomItem
                iconUrl={Home}
                title={lang.home}
                link={websiteRoutes.home}
                active={isActivePath(websiteRoutes.home)}
            />
            <NavigatorBottomItem
                iconUrl={GameHistory}
                title={lang.gamesHistory}
                link={websiteRoutes.gameHistory}
                active={isActivePath(websiteRoutes.gameHistory)}
            />
        </>}

        {/*<NavigatorBottomItem*/}
        {/*    title={'Menu'}*/}
        {/*    buttonMode*/}
        {/*    icon={MenuIcon}*/}
        {/*/>*/}
    </Box>
}