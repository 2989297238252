export const PrivacyPolicyPageFr = () => {
    return <>
            <h1><strong>Politique de confidentialité</strong></h1>
            <p>Cette déclaration de confidentialité pour Uibet.org (« Entreprise », « nous », « notre »), décrit
                comment et pourquoi nous pouvons collecter, stocker, utiliser et/ou partager (« traiter ») vos
                informations lorsque vous utilisez nos services (« Services »), tels que :</p>
            <ul>
                <li>
                    <p>Visiter notre site Web à l'adresse www.Uibet.org, qui renvoie à cette déclaration de
                        confidentialité</p>
                </li>
                <li>
                    <p>Interagir avec nous de toute autre manière liée, y compris lors de ventes, de marketing ou
                        d'événements</p>
                </li>
            </ul>
            <p>Questions ou préoccupations ? Lire cette déclaration de confidentialité vous aidera à comprendre vos
                droits et vos choix en matière de confidentialité. Si vous n'êtes pas d'accord avec nos politiques
                et pratiques, veuillez ne pas utiliser nos Services. Si vous avez encore des questions ou des
                préoccupations, contactez-nous à l'adresse <a href="mailto:support@uibet.com">support@Uibet.org</a>
            </p>
            <p>RÉSUMÉ DES POINTS CLÉS</p>
            <p>Ce résumé présente les points clés de notre déclaration de confidentialité.</p>
            <p>Quelles informations personnelles traitons-nous ? Lorsque vous visitez, utilisez ou naviguez dans nos
                Services, nous pouvons traiter des informations personnelles en fonction de la manière dont vous
                interagissez avec Uibet.org et les Services, des choix que vous faites et des produits et
                fonctionnalités que vous utilisez.</p>
            <p>Traitons-nous des informations personnelles sensibles ? Nous pouvons traiter des informations
                personnelles sensibles lorsque cela est nécessaire avec votre consentement ou autrement autorisé par
                la loi applicable.</p>
            <p>Recevons-nous des informations de tiers ? Nous ne recevons aucune information de tiers.</p>
            <p>Comment traitons-nous vos informations ? Nous traitons vos informations pour fournir, améliorer et
                administrer nos Services, communiquer avec vous, assurer la sécurité et prévenir la fraude, et pour
                respecter la loi. Nous pouvons également traiter vos informations à d'autres fins avec votre
                consentement. Nous traitons vos informations uniquement lorsque nous avons une raison légale valable
                de le faire.</p>
            <p>Dans quelles situations et avec quels types de parties partageons-nous des informations personnelles
                ? Nous pouvons partager des informations dans des situations spécifiques et avec des catégories
                spécifiques de tiers.</p>
            <p>Comment gardons-nous vos informations en sécurité ? Nous avons mis en place des processus et des
                procédures organisationnels et techniques pour protéger vos informations personnelles. Cependant,
                aucune transmission électronique sur Internet ou technologie de stockage ne peut être garantie à 100
                % sécurisée, nous ne pouvons donc pas promettre ou garantir que des pirates, des cybercriminels ou
                d'autres tiers non autorisés ne pourront pas contourner nos mesures de sécurité et collecter,
                accéder, voler ou modifier vos informations.</p>
            <p>Quels sont vos droits ? Selon l'endroit où vous vous trouvez géographiquement, la loi sur la
                confidentialité applicable peut signifier que vous avez certains droits concernant vos informations
                personnelles.</p>
            <p>Comment exercer vos droits ? Le moyen le plus simple d'exercer vos droits est de nous contacter. Nous
                examinerons et traiterons toute demande conformément aux lois sur la protection des données
                applicables.</p>
            <p>TABLE DES MATIÈRES</p>
            <ol>
                <li>QUELLES INFORMATIONS COLLECTONS-NOUS ?</li>
                <li>COMMENT TRAITONS-NOUS VOS INFORMATIONS ?</li>
                <li>SUR QUELLES BASES JURIDIQUES NOUS FONDER POUR TRAITER VOS INFORMATIONS PERSONNELLES ?</li>
                <li>QUAND ET AVEC QUI PARTAGEONS-NOUS VOS INFORMATIONS PERSONNELLES ?</li>
                <li>QUELLE EST NOTRE POSITION SUR LES SITES WEB TIERS ?</li>
                <li>UTILISONS-NOUS DES COOKIES ET D'AUTRES TECHNOLOGIES DE SUIVI ?</li>
                <li>PENDANT COMBIEN DE TEMPS CONSERVONS-NOUS VOS INFORMATIONS ?</li>
                <li>COMMENT GARDONS-NOUS VOS INFORMATIONS EN SÉCURITÉ ?</li>
                <li>COLLECTONS-NOUS DES INFORMATIONS AUPRÈS DES MINEURS ?</li>
                <li>QUELS SONT VOS DROITS EN MATIÈRE DE CONFIDENTIALITÉ ?</li>
                <li>CONTRÔLES POUR LES FONCTIONNALITÉS « DO-NOT-TRACK »</li>
                <li>METTONS-NOUS À JOUR CETTE DÉCLARATION ?</li>
                <li>COMMENT NOUS CONTACTER À PROPOS DE CETTE DÉCLARATION ?</li>
                <li>COMMENT CONSULTER, METTRE À JOUR OU SUPPRIMER LES DONNÉES QUE NOUS COLLECTONS À VOTRE SUJET ?
                </li>
            </ol>
            <h2>QUELLES INFORMATIONS COLLECTONS-NOUS ?</h2>
            <h3>Informations personnelles que vous nous communiquez</h3>
            <p><strong>En bref :</strong> Nous collectons les informations personnelles que vous nous fournissez.
            </p>
            <p>Nous collectons les informations personnelles que vous nous communiquez volontairement lorsque vous
                vous inscrivez sur les Services, manifestez un intérêt pour obtenir des informations sur nous ou sur
                nos produits et Services, participez à des activités sur les Services, ou nous contactez
                autrement.</p>
            <p>Informations personnelles fournies par vous. Les informations personnelles que nous collectons
                dépendent du contexte de vos interactions avec nous et les Services, des choix que vous faites, et
                des produits et fonctionnalités que vous utilisez. Ces informations personnelles peuvent inclure les
                éléments suivants :</p>
            <ul>
                <li>noms</li>
                <li>numéros de téléphone</li>
                <li>adresses e-mail</li>
                <li>adresses postales</li>
                <li>noms d'utilisateur</li>
                <li>mots de passe</li>
                <li>numéros de carte de débit/crédit</li>
            </ul>

            <p>Informations sensibles. Lorsque cela est nécessaire, avec votre consentement ou comme autrement
                autorisé par la loi applicable, nous traitons les catégories d'informations sensibles suivantes
                :</p>
            <ul>
                <li>
                    <p>informations révélant l'origine raciale ou ethnique</p>
                </li>
            </ul>
            <p>Toutes les informations personnelles que vous nous fournissez doivent être vraies, complètes et
                exactes, et vous devez nous informer de tout changement concernant ces informations
                personnelles.</p>
            <p>Informations collectées automatiquement</p>
            <p><strong>En bref :</strong> Certaines informations — telles que votre adresse de protocole Internet
                (IP) et/ou les caractéristiques de votre navigateur et de votre appareil — sont collectées
                automatiquement lorsque vous visitez nos Services.</p>
            <p>Nous collectons automatiquement certaines informations lorsque vous visitez, utilisez ou naviguez
                dans les Services. Ces informations ne révèlent pas votre identité spécifique (comme votre nom ou
                vos coordonnées), mais peuvent inclure des informations sur votre appareil et son utilisation,
                telles que votre adresse IP, les caractéristiques de votre navigateur et de votre appareil, le
                système d'exploitation, les préférences linguistiques, les URL de provenance, le nom de l'appareil,
                le pays, la localisation, des informations sur la manière dont vous utilisez nos Services et leur
                moment d'utilisation, ainsi que d'autres informations techniques. Ces informations sont
                principalement nécessaires pour maintenir la sécurité et le bon fonctionnement de nos Services,
                ainsi que pour nos analyses internes et nos rapports.</p>
            <p>Comme de nombreuses entreprises, nous collectons également des informations via des cookies et des
                technologies similaires.</p>
            <p>Les informations que nous collectons incluent :</p>
            <ul>
                <li>
                    <p>Données de journal et d'utilisation. Les données de journal et d'utilisation sont des
                        informations liées aux services, aux diagnostics, à l'utilisation et aux performances que
                        nos serveurs collectent automatiquement lorsque vous accédez ou utilisez nos Services et que
                        nous enregistrons dans des fichiers journaux. Selon la manière dont vous interagissez avec
                        nous, ces données peuvent inclure votre adresse IP, les informations sur votre appareil, le
                        type et les paramètres du navigateur, ainsi que des informations sur votre activité dans les
                        Services (telles que les horodatages associés à votre utilisation, les pages et fichiers
                        consultés, les recherches et d'autres actions telles que les fonctionnalités que vous
                        utilisez), les informations sur les événements liés à l'appareil (telles que l'activité
                        système, les rapports d'erreurs (parfois appelés "dumps de plantage") et les paramètres
                        matériels).</p>
                </li>
                <li>
                    <p>Données de l'appareil. Nous collectons des données sur les appareils tels que les
                        informations concernant votre ordinateur, téléphone, tablette ou autre appareil que vous
                        utilisez pour accéder aux Services. Selon l'appareil utilisé, ces données peuvent inclure
                        des informations telles que votre adresse IP (ou serveur proxy), les numéros
                        d'identification de l'appareil et de l'application, la localisation, le type de navigateur,
                        le modèle de matériel, le fournisseur de services Internet et/ou l'opérateur mobile, le
                        système d'exploitation et les informations de configuration système.</p>
                </li>
                <li>
                    <p>Données de localisation. Nous collectons des données de localisation, telles que des
                        informations sur la localisation de votre appareil, qui peuvent être précises ou imprécises.
                        La quantité d'informations que nous collectons dépend du type et des paramètres de
                        l'appareil que vous utilisez pour accéder aux Services. Par exemple, nous pouvons utiliser
                        le GPS et d'autres technologies pour collecter des données de géolocalisation qui nous
                        indiquent votre position actuelle (basée sur votre adresse IP). Vous pouvez refuser de nous
                        permettre de collecter ces informations en refusant l'accès ou en désactivant le paramètre
                        de localisation sur votre appareil. Cependant, si vous choisissez de refuser, vous pourriez
                        ne pas être en mesure d'utiliser certains aspects des Services.</p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <ol>
                <li>
                    <p>COMMENT TRAITONS-NOUS VOS INFORMATIONS ?</p>
                </li>
            </ol>
            <p><strong>En bref :</strong> Nous traitons vos informations pour fournir, améliorer et administrer nos
                Services, communiquer avec vous, pour la sécurité et la prévention des fraudes, et pour respecter la
                loi. Nous pouvons également traiter vos informations à d'autres fins avec votre consentement.</p>
            <p>Nous traitons vos informations personnelles pour diverses raisons, selon la manière dont vous
                interagissez avec nos Services, notamment :</p>
            <ul>
                <li>
                    <p>Pour faciliter la création et l'authentification des comptes et gérer les comptes
                        d'utilisateur. Nous pouvons traiter vos informations pour vous permettre de créer et de vous
                        connecter à votre compte, ainsi que pour maintenir votre compte en état de
                        fonctionnement.</p>
                </li>
                <li>
                    <p>Pour demander des retours. Nous pouvons traiter vos informations lorsque cela est nécessaire
                        pour demander des retours et pour vous contacter au sujet de votre utilisation de nos
                        Services.</p>
                </li>
                <li>
                    <p>Pour vous envoyer des communications marketing et promotionnelles. Nous pouvons traiter les
                        informations personnelles que vous nous envoyez à des fins de marketing, si cela est
                        conforme à vos préférences en matière de marketing. Vous pouvez vous désinscrire de nos
                        e-mails marketing à tout moment. Pour plus d'informations, voir "QUELS SONT VOS DROITS EN
                        MATIÈRE DE CONFIDENTIALITÉ ?".</p>
                </li>
                <li>
                    <p>Pour vous fournir de la publicité ciblée. Nous pouvons traiter vos informations pour
                        développer et afficher un contenu et une publicité personnalisés en fonction de vos
                        intérêts, de votre localisation et d'autres critères.</p>
                </li>
                <li>
                    <p>Pour protéger nos Services. Nous pouvons traiter vos informations dans le cadre de nos
                        efforts pour assurer la sécurité de nos Services, y compris la surveillance et la prévention
                        des fraudes.</p>
                </li>
                <li>
                    <p>Pour identifier les tendances d'utilisation. Nous pouvons traiter des informations sur la
                        manière dont vous utilisez nos Services afin de mieux comprendre leur utilisation et
                        d'améliorer leur fonctionnement.</p>
                </li>
                <li>
                    <p>Pour évaluer l'efficacité de nos campagnes marketing et promotionnelles. Nous pouvons traiter
                        vos informations pour mieux comprendre comment fournir des campagnes marketing et
                        promotionnelles qui sont les plus pertinentes pour vous.</p>
                </li>
                <li>
                    <p>Pour préserver ou protéger un intérêt vital. Nous pouvons traiter vos informations lorsque
                        cela est nécessaire pour préserver ou protéger un intérêt vital d'une personne, comme pour
                        prévenir des dommages.</p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <ol>
                <li>
                    <p>QUELLES BASES LÉGALES UTILISONS-NOUS POUR TRAITER VOS INFORMATIONS ?</p>
                </li>
            </ol>
            <p><strong>En bref :</strong> Nous traitons vos informations personnelles uniquement lorsque nous
                estimons que cela est nécessaire et que nous avons une raison légale valable (c'est-à-dire une base
                légale) pour le faire en vertu des lois applicables, comme avec votre consentement, pour respecter
                les lois, pour vous fournir des services afin de remplir nos obligations contractuelles, pour
                protéger vos droits, ou pour poursuivre nos intérêts commerciaux légitimes.</p>
            <p>Si vous vous trouvez dans l'Union européenne ou au Royaume-Uni, cette section s'applique à vous.</p>
            <p>Le Règlement général sur la protection des données (RGPD) et le RGPD du Royaume-Uni nous obligent à
                expliquer les bases légales valides sur lesquelles nous nous appuyons pour traiter vos informations
                personnelles. Ainsi, nous pouvons nous appuyer sur les bases légales suivantes pour traiter vos
                informations personnelles :</p>
            <ul>
                <li>
                    <p>Consentement. Nous pouvons traiter vos informations si vous nous avez donné l'autorisation
                        (c'est-à-dire votre consentement) d'utiliser vos informations personnelles à une fin
                        spécifique. Vous pouvez retirer votre consentement à tout moment.</p>
                </li>
                <li>
                    <p>Intérêts légitimes. Nous pouvons traiter vos informations lorsque nous estimons que cela est
                        raisonnablement nécessaire pour atteindre nos intérêts commerciaux légitimes et que ces
                        intérêts ne portent pas atteinte à vos droits et libertés fondamentaux. Par exemple, nous
                        pouvons traiter vos informations personnelles pour :</p>
                </li>
                <li>
                    <p>Envoyer aux utilisateurs des informations sur des offres spéciales et des réductions
                        concernant nos produits et services</p>
                </li>
                <li>
                    <p>Développer et afficher un contenu publicitaire personnalisé et pertinent pour nos
                        utilisateurs</p>
                </li>
                <li>
                    <p>Analyser comment nos services sont utilisés afin de les améliorer pour engager et fidéliser
                        les utilisateurs</p>
                </li>
                <li>
                    <p>Soutenir nos activités marketing</p>
                </li>
                <li>
                    <p>Diagnostiquer des problèmes et/ou prévenir des activités frauduleuses</p>
                </li>
                <li>
                    <p>Comprendre comment nos utilisateurs utilisent nos produits et services afin d'améliorer leur
                        expérience utilisateur</p>
                </li>
                <li>
                    <p>Obligations légales. Nous pouvons traiter vos informations lorsque nous estimons que cela est
                        nécessaire pour respecter nos obligations légales, par exemple pour coopérer avec un
                        organisme d'application de la loi ou une agence de régulation, exercer ou défendre nos
                        droits légaux, ou divulguer vos informations comme preuve dans une procédure judiciaire à
                        laquelle nous sommes partie.</p>
                </li>
                <li>
                    <p>Intérêts vitaux. Nous pouvons traiter vos informations lorsque nous estimons que cela est
                        nécessaire pour protéger vos intérêts vitaux ou ceux d'un tiers, comme dans des situations
                        impliquant des menaces potentielles pour la sécurité de toute personne.</p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <ol>
                <li>
                    <p>QUAND ET AVEC QUI PARTAGEONS-NOUS VOS INFORMATIONS PERSONNELLES ?</p>
                </li>
            </ol>
            <p><strong>En bref :</strong> Nous pouvons partager vos informations dans des situations spécifiques
                décrites dans cette section et/ou avec les catégories de tiers suivantes.</p>
            <p>Fournisseurs, consultants et autres prestataires de services tiers. Nous pouvons partager vos données
                avec des fournisseurs tiers, prestataires de services, sous-traitants ou agents (« tiers ») qui
                fournissent des services pour nous ou en notre nom et nécessitent un accès à ces informations pour
                effectuer leur travail. Les catégories de tiers avec lesquels nous pouvons partager des informations
                personnelles sont les suivantes :</p>
            <ul>
                <li>
                    <p>Réseaux publicitaires</p>
                </li>
            </ul>
            <p>Nous pouvons également partager vos informations personnelles dans les situations suivantes :</p>
            <ul>
                <li>
                    <p>Transferts commerciaux. Nous pouvons partager ou transférer vos informations dans le cadre ou
                        au cours de négociations relatives à toute fusion, vente d'actifs de l'entreprise,
                        financement ou acquisition de tout ou partie de notre entreprise par une autre société.</p>
                </li>
            </ul>
            <p>&nbsp;</p>
            <ol>
                <li>
                    <p>QUELLE EST NOTRE POSITION SUR LES SITES WEB TIERS ?</p>
                </li>
            </ol>
            <p><strong>En bref :</strong> Nous ne sommes pas responsables de la sécurité des informations que vous
                partagez avec des tiers qui peuvent être liés ou qui font de la publicité sur nos Services, mais qui
                ne sont pas affiliés à nos Services.</p>
            <p>Les Services peuvent contenir des liens vers des sites web tiers, des services en ligne ou des
                applications mobiles, et/ou inclure des publicités de tiers qui ne sont pas affiliés à nous et qui
                peuvent rediriger vers d'autres sites web, services ou applications. En conséquence, nous ne faisons
                aucune garantie concernant ces tiers et nous ne serons pas responsables de toute perte ou dommage
                causé par l'utilisation de tels sites web, services ou applications tiers. L'inclusion d'un lien
                vers un site web, un service ou une application tiers n'implique pas notre approbation. Nous ne
                pouvons garantir la sécurité et la confidentialité des données que vous fournissez à des tiers.
                Toute donnée collectée par des tiers n'est pas couverte par cette déclaration de confidentialité.
                Nous ne sommes pas responsables du contenu ni des pratiques en matière de confidentialité et de
                sécurité des tiers, y compris d'autres sites web, services ou applications qui peuvent être liés à
                ou depuis les Services. Vous devriez examiner les politiques de ces tiers et les contacter
                directement pour répondre à vos questions.</p>
            <p>&nbsp;</p>
            <ol>
                <li>
                    <p>UTILISONS-NOUS DES COOKIES ET AUTRES TECHNOLOGIES DE SUIVI ?</p>
                </li>
            </ol>
            <p><strong>En bref :</strong> Nous pouvons utiliser des cookies et d'autres technologies de suivi pour
                collecter et stocker vos informations.</p>
            <p>Nous pouvons utiliser des cookies et des technologies de suivi similaires (comme des balises web et
                des pixels) pour accéder à ou stocker des informations. Des informations spécifiques sur la façon
                dont nous utilisons ces technologies et comment vous pouvez refuser certains cookies sont décrites
                dans notre Avis sur les cookies.</p>
            <p>&nbsp;</p>
            <ol>
                <li>
                    <p>PENDANT COMBIEN DE TEMPS CONSERVONS-NOUS VOS INFORMATIONS ?</p>
                </li>
            </ol>
            <p><strong>En bref :</strong> Nous conservons vos informations aussi longtemps que nécessaire pour
                atteindre les objectifs décrits dans cette déclaration de confidentialité, sauf si la loi l'exige
                autrement.</p>
            <p>Nous ne conserverons vos informations personnelles que le temps nécessaire pour atteindre les
                objectifs décrits dans cette déclaration de confidentialité, sauf si une période de conservation
                plus longue est requise ou autorisée par la loi (par exemple, pour des raisons fiscales, comptables
                ou autres obligations légales). Aucun objectif de cette déclaration n'exigera que nous conservions
                vos informations personnelles plus longtemps que la période pendant laquelle vous avez un compte
                actif chez nous.</p>
            <p>Lorsque nous n'avons plus de besoin commercial légitime de traiter vos informations personnelles,
                nous les supprimerons ou les rendrons anonymes. Si cela n'est pas possible (par exemple, parce que
                vos informations personnelles ont été stockées dans des archives de sauvegarde), nous stockerons vos
                informations personnelles de manière sécurisée et les isolerons de tout traitement ultérieur jusqu'à
                ce que la suppression soit possible.</p>
            <p>&nbsp;</p>
            <ol>
                <li>
                    <p>COMMENT PROTÉGEONS-NOUS VOS INFORMATIONS ?</p>
                </li>
            </ol>
            <p><strong>En bref :</strong> Nous nous efforçons de protéger vos informations personnelles grâce à des
                mesures de sécurité organisationnelles et techniques.</p>
            <p>Nous avons mis en place des mesures de sécurité techniques et organisationnelles appropriées et
                raisonnables pour protéger la sécurité des informations personnelles que nous traitons. Cependant,
                malgré nos précautions et nos efforts pour sécuriser vos informations, aucune transmission
                électronique sur Internet ni technologie de stockage de données ne peut être garantie comme étant
                100 % sécurisée. Par conséquent, nous ne pouvons pas promettre ou garantir que des pirates, des
                cybercriminels ou d'autres tiers non autorisés ne pourront pas contourner notre sécurité et accéder
                à vos informations de manière inappropriée. Bien que nous fassions de notre mieux pour protéger vos
                informations personnelles, la transmission d'informations personnelles vers et depuis nos Services
                se fait à vos propres risques. Vous ne devriez accéder à nos Services que dans un environnement
                sécurisé.</p>
            <p>&nbsp;</p>
            <ol>
                <li>
                    <p>COLLECTONS-NOUS DES INFORMATIONS AUPRÈS DES MINEURS ?</p>
                </li>
            </ol>
            <p><strong>En bref :</strong> Nous ne collectons pas sciemment de données auprès d'enfants de moins de
                18 ans ni ne leur adressons de publicités.</p>
            <p>Nous ne sollicitons pas sciemment des données ni ne faisons de la publicité auprès d'enfants de moins
                de 18 ans. En utilisant les Services, vous déclarez avoir au moins 18 ans ou être le parent ou
                tuteur d'un mineur et donner votre consentement pour l'utilisation des Services par ce mineur. Si
                nous apprenons que des informations personnelles ont été collectées auprès d'utilisateurs de moins
                de 18 ans, nous désactiverons le compte et prendrons des mesures raisonnables pour supprimer ces
                données de nos archives. Si vous avez connaissance de données que nous avons pu collecter auprès
                d'enfants de moins de 18 ans, veuillez nous contacter à <a
                    href="mailto:support@uibet.com">support@Uibet.org</a>.</p>
            <p>&nbsp;</p>
            <ol>
                <li>
                    <p>COMMANDES POUR LES FONCTIONNALITÉS « DO-NOT-TRACK »</p>
                </li>
            </ol>
            <p>La plupart des navigateurs web et certains systèmes d'exploitation mobiles et applications mobiles
                incluent une fonctionnalité « Do-Not-Track » (« DNT ») que vous pouvez activer pour signaler votre
                préférence en matière de confidentialité afin que vos activités de navigation en ligne ne soient pas
                surveillées et collectées. À ce stade, aucune norme technologique uniforme pour reconnaître et
                implémenter les signaux DNT n'a été finalisée. En conséquence, nous ne répondons pas actuellement
                aux signaux DNT des navigateurs ni à tout autre mécanisme qui communique automatiquement votre choix
                de ne pas être suivi en ligne. Si une norme de suivi en ligne est adoptée et que nous devons la
                suivre à l'avenir, nous vous en informerons dans une version révisée de cette déclaration de
                confidentialité.</p>
            <p>&nbsp;</p>
            <ol>
                <li>
                    <p>METTONS-NOUS À JOUR CETTE DÉCLARATION ?</p>
                </li>
            </ol>
            <p><strong>En bref :</strong> Oui, nous mettrons à jour cette déclaration si nécessaire pour rester en
                conformité avec les lois applicables.</p>
            <p>Nous pouvons mettre à jour cette déclaration de confidentialité de temps à autre. La version mise à
                jour sera indiquée par une date révisée et sera effective dès qu'elle sera accessible. Si nous
                apportons des modifications importantes à cette déclaration, nous pourrons vous en informer en
                publiant un avis bien visible sur ces modifications ou en vous envoyant directement une
                notification. Nous vous encourageons à consulter fréquemment cette déclaration de confidentialité
                pour rester informé sur la manière dont nous protégeons vos informations.</p>
            <p>&nbsp;</p>
            <ol>
                <li>
                    <p>COMMENT NOUS CONTACTER À PROPOS DE CETTE DÉCLARATION ?</p>
                </li>
            </ol>
            <p>Si vous avez des questions ou des commentaires concernant cette déclaration, vous pouvez nous
                contacter à <a href="mailto:support@uibet.com">support@Uibet.org</a>.</p>
            <p>&nbsp;</p>
            <ol>
                <li>
                    <p>COMMENT CONSULTER, METTRE À JOUR OU SUPPRIMER LES DONNÉES QUE NOUS COLLECTONS SUR VOUS ?</p>
                </li>
            </ol>
            <p>En fonction des lois applicables de votre pays, vous pouvez avoir le droit de demander l'accès aux
                informations personnelles que nous collectons sur vous, de les modifier ou de les supprimer. Pour
                demander à consulter, mettre à jour ou supprimer vos informations personnelles, veuillez nous
                contacter à <a href="mailto:support@uibet.com">support@Uibet.org</a>.</p>
    </>
}