import {useUser} from "./useUser";
import React, {useState} from "react";

export const useLoginFormLogic = () => {
    const { logIn: sendLogin, isLogInLoading } = useUser()
    const [ username, setUsername ] = useState<string>('')
    const [ password, setPassword ] = useState<string>('')

    const usernameFieldHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value)
    }

    const passwordFieldHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
    }

    const submitDisabled = (): boolean => username === '' || password === '' || isLogInLoading

    return {username, password, usernameFieldHandler, passwordFieldHandler, submitDisabled, sendLogin, isLogInLoading}
}