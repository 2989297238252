import {FC} from "react";
import {Page} from "../components/layout/Page";
import {PageContent} from "../components/layout/PageContent";

export const ResponsibleGamingPage: FC = () => {
    return <Page>
        <PageContent>
            <h1 ><strong>Responsible gaming</strong></h1>
            <p >We apply a policy of ethical and responsible game offering so that each user can
                participate responsibly in every betting activity. We are devoted to offering our customers a risk-free
                and enjoyable experience.</p>
            <p ><strong>Responsible activity</strong><br/>At Uibet.org, responsible
                gaming is our most valued principle that we hold at the core of our operations. We understand that the
                product we provide is exciting and makes for a great pastime activity. We also understand the risk that
                is associated with immoderate gambling and therefore encourages a transparent responsible activity
                environment for our valued customers.</p>
            <p ><strong>Responsible Operator</strong><br/>Since most of our customers are
                already enjoying our services frequently in an enjoyable and responsible manner, we believe at
                Uibet.org that our stance on proactive responsible gaming endorsement demonstrates evidence that
                we are a truly responsible operator.</p>
            <p ><strong>Maintaining control</strong><br/>Whilst the majority of our customers
                gamble within their means for some it can be more difficult. In order to maintain control of your
                gambling habits, we would like you to bear in mind the following points:</p>
            <ul>
                <li>
                    <p >Gambling should be done in moderation and undertaken as a form of
                        entertainment and not as a way of making a living</p>
                </li>
                <li>
                    <p >Avoid chasing losses</p>
                </li>
                <li>
                    <p >Only gamble when you can cover the losses</p>
                </li>
                <li>
                    <p >Keep track of the time and monitor the amount of money you spend</p>
                </li>
                <li>
                    <p >Should you need a break from gambling, self-exclusion can be set by
                        contacting <a href="mailto:support@Uibet.org">support@Uibet.org</a></p>
                </li>
                <li>
                    <p >If you need to talk to someone about any concerns you may have with
                        your gambling, please contact one of the organisations detailed below.</p>
                </li>
            </ul>
            <p ><strong>Underage Gambling</strong><br/>We actively prevent any underaged persons
                from attempting to register at our websites.&nbsp;<br/>If you are under the age of 18, or the legal
                gambling age for your jurisdiction (whichever is higher) you are not allowed to play at
                Uibet.org.</p>
            <p ><strong>**Please note that anyone under the age of 18 is found to be using this
                site, will have its profits frozen and may also be reported to the relevant authorities.**</strong></p>
        </PageContent>
    </Page>
}