import {useMemo} from "react";
import {usePlayGames} from "../../../features/play_games/usePlayGames";
import {GameType} from "../../../features/play_games/playGamesModels";

export const useFilterGames = ( mode: "featured" | "new" | "game_type" | "provider" | "all_games", gameType?: GameType, provider?: string) => {
    const {games} = usePlayGames()

    return useMemo(() => {
        if (games !== null) {
            switch (mode) {
                case 'featured':
                    return games.filter(game => game.game_attribute === 'featured')
                case 'new':
                    return games.filter(game => game.game_attribute === 'new')
                case 'game_type':
                    if (gameType !== undefined) {
                        if (['game_shows', 'roulette', 'blackjack'].includes(gameType)) {
                            return games.filter(game => game.game_subtype === gameType)
                        } else {
                            return games.filter(game => game.game_type === gameType)
                        }
                    }
                    break
                case 'provider':
                    if (provider !== undefined) {
                        return games.filter(game => game.provider_name === provider)
                    }
                    break
                case "all_games":
                    return games
            }
        }
        return []
    }, [games, mode, gameType, provider])
}