import {FC, ReactNode} from "react";
import {Box, Stack} from "@mui/material";
import {BottomMobileMenu} from "./BottomMobileMenu";
import {useCommonMediaQueries} from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {Header} from "./header/Header";
import {Footer} from "./footer/Footer";

export const Page: FC<{children: ReactNode, dashboardMode?: boolean}> = ({children, dashboardMode = false}) => {
    const { mediumScreen: md} = useCommonMediaQueries()

    return <Stack height={'100dvh'}>
        <Stack flexGrow={1} overflow={'auto'}>
            <Header dashboardMode={dashboardMode}/>
            {children}
            <Footer/>
        </Stack>
        {md && <Box minHeight={'56px'} maxHeight={'56px'}>
            <BottomMobileMenu dashboardMode={dashboardMode}/>
        </Box>}
    </Stack>
}