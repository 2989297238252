import React, { FC, useEffect, useState } from "react";
import {
    alpha,
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent, Stack, useTheme
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import {useCommonMediaQueries} from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {useLanguage} from "../../../features/localisation/useLanguage";

interface PageSizeSwitcherProps {
    pageSize: number
}

export const PageSizeSwitcher: FC<PageSizeSwitcherProps> = ({pageSize}) => {
    const theme = useTheme()
    const { smallScreen } = useCommonMediaQueries()
    const { languagePack: { pack: {common: lang}}} = useLanguage()
    const [sizes, setSizes] = useState<string[]>([
        '10', '20', '30', '40'
    ])

    const navigate = useNavigate();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);

    useEffect(() => {
        if (!sizes.includes(pageSize.toString())) {
            setSizes([
                ...sizes,
                pageSize.toString()
            ])
        }
    }, [pageSize, sizes])

    const handleSelect = (event: SelectChangeEvent<string>) => {
        searchParams.set('pageSize', event.target.value);

        navigate({
            pathname: location.pathname,
            search: searchParams.toString(),
        });
    }

    return <Stack minWidth={smallScreen ? '140px' : '200px'}>
        <FormControl fullWidth>
            {/*<InputLabel>{lang.pageSizeSwitcherLabel}</InputLabel>*/}
            <Typography textAlign={'right'} pr={'8px'}>{lang.pageSizeSwitcherLabel}:</Typography>
            <Select
                sx={{
                    borderRadius: '12px',
                    backgroundColor: theme.palette.background.paper,
                    color: 'white', // Устанавливает цвет текста в белый
                    '.MuiOutlinedInput-notchedOutline': {
                        borderColor: alpha('#fff', 0.5),
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: alpha('#fff', 1),
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: alpha('#fff', 0.8),
                    },
                }}
                size="small"
                value={sizes.includes(pageSize.toString()) ? pageSize.toString() : '30'}
                onChange={handleSelect}
            >
                {sizes.map((size) => (
                    <MenuItem key={'page_size_' + size} value={size}>
                        {size}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    </Stack>
};
