import {FC} from "react";
import {Box, Stack, Typography, useTheme} from "@mui/material";
import {NavigatorBottomItemProps} from "./NavigatorBottomItem.interface";
import {Link} from "react-router-dom";


export const NavigatorBottomItem: FC<NavigatorBottomItemProps> = (props) => {
    return <Box
        flex={1}
        sx={theme => ({
            '& a': {
                textDecoration: 'none',
                color: theme.palette.text.primary
            },
            // backgroundColor: '#3a55de',
        })}
    >
        {props.buttonMode ? <NavigatorBottomItemBase {...props}/> : <Link to={props.link ?? '#'}><NavigatorBottomItemBase {...props}/></Link>}
    </Box>
}

const NavigatorBottomItemBase: FC<NavigatorBottomItemProps> = ({title, active, iconUrl, icon: Icon}) => {
    const theme = useTheme()

    return <Stack
        alignItems={'center'}
        justifyContent={'start'}
        sx={{
            height: '100%',
            cursor: 'pointer',
            backgroundColor: active ? '#482f18' : undefined,
            color: theme.palette.text.primary
        }}
        py={1}
        gap={0.6}
    >
        {iconUrl ? <Box
            height={'22px'}
            minHeight={'22px'}
            width={'22px'}
            sx={{
                backgroundImage: `url(${iconUrl})`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat'
            }}
        /> : Icon && <Icon
            sx={{fontSize: '30px', m: '-4px'}}
            color={'inherit'}
        />}
        <Typography
            textAlign={'center'}
            fontSize={12}
            lineHeight={'12px'}
        >
            {title}
        </Typography>
    </Stack>
}