import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from "react";

const FacebookIcon: FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path clipRule={'evenodd'} fillRule={'evenodd'} fill={'currentColor'} d="M12,0C5.4,0,0,5.4,0,12c0,6.6,5.4,12,12,12c6.6,0,12-5.4,12-12C24,5.4,18.6,0,12,0z M13.2,12.5v6.5h-2.7v-6.5
	H9.2v-2.2h1.3V8.9c0-1.8,0.8-2.9,2.9-2.9h1.8v2.2h-1.1c-0.8,0-0.9,0.3-0.9,0.9l0,1.1h2L15,12.5H13.2z"/>
            </svg>
        </SvgIcon>
    );
}

export default FacebookIcon;