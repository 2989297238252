import React, {FC, useState} from "react";
import {Box, Button, Dialog, Stack, TextField, Typography, useTheme} from "@mui/material";
import {SearchGameModalProps} from "./SearchGameModal.interface";
import {useCommonMediaQueries} from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {Close} from "@mui/icons-material";
import {useLanguage} from "../../features/localisation/useLanguage";
import {useSearchLogic} from "./SearchGameModal.hook";
import {ProviderCard} from "../casino/casino_grid/ProviderCard";
import {websiteRoutes} from "../../features/routes/routes";
import {getMediaCDN} from "../../config";
import {GameCard} from "../casino/casino_grid/GameCard";

export const SearchGameModal: FC<SearchGameModalProps> = ({close}) => {
    const theme = useTheme()


    const { languagePack: {pack: {searchWindow: lang}}} = useLanguage()
    const [ opened, setOpened ] = useState (true)
    const { mediumScreen: md } = useCommonMediaQueries()
    const { searchValue, setSearchValue, foundGames, foundProviders } = useSearchLogic()

    const gap = theme.spacing(1)
    const itemSize = md ? `calc((100% - ${gap} * (3 - 1)) / 3)` : `calc((100% - ${gap} * (8 - 1)) / 8)`
    const cdn = getMediaCDN()

    const pendingClose = () => {
        setOpened (false);
        setTimeout (() => close (), 500)
    }

    return <Dialog
        fullScreen={md}
        maxWidth={ 'md' }
        fullWidth
        open={ opened }
        onClose={ pendingClose }
        sx={{
            backdropFilter: 'blur(5px)',
            '& .MuiDialog-paper': {
                borderRadius: md ? 0 : '16px',
                backgroundColor: '#E2E2E2',
                p: 4,
                color: 'black',
                height: '100%',
                position: 'relative'
            },
        }}
    >
        <Button
            onClick={pendingClose}
            sx={{
                minWidth: '36px',
                maxWidth: '36px',
                height: '36px',
                justifyContent: 'center',
                display: 'flex',
                backgroundColor: 'white',
                position: 'absolute',
                top: '24px',
                right: '24px',
            }}
            variant={'outlined'}
        >
            <Close/>
        </Button>

        <Stack
            pt={2}
            gap={2}
        >
            <Typography variant={'h2'}>
                {lang.title}
            </Typography>
            <TextField
                helperText={lang.searchHelper}
                autoFocus
                value={searchValue}
                onChange={e => setSearchValue (e.target.value)}
                fullWidth
                variant={'outlined'}
                placeholder={lang.searchPlaceholder}
                sx={{
                    '& .MuiOutlinedInput-root': {
                        fontWeight: 700,
                        fontSize: '1.2rem'
                    },
                    '& .MuiFormHelperText-root': {
                        color: 'black' // Устанавливаем черный цвет для helperText
                    }
                }}
            />


            {foundProviders.length > 0 && <Stack gap={2}>
                <Typography variant={'h3'}>
                    {lang.foundProviders}
                </Typography>
                <Box
                    display={'flex'}
                    gap={gap}
                    flexWrap={'wrap'}
                >
                    {foundProviders.slice(0, 16).map(provider => {
                        return <ProviderCard
                            horizontalMode
                            providerLink={`${websiteRoutes.casino}?provider=${provider.provider_name}`}
                            id={provider.provider_name}
                            itemSize={itemSize}
                            md={false}
                            // picUrl={`${cdn}/provider_img/hor/${provider.provider_name}.png`}
                            picUrl={`${cdn}/provider_img/hor/${provider.logo}`}
                        />
                    })}
                </Box>
            </Stack>}

            {foundGames.length > 0 && <Stack gap={2}>
                <Typography variant={'h3'}>
                    {lang.foundGames}
                </Typography>
                <Box
                    display={'flex'}
                    gap={gap}
                    flexWrap={'wrap'}
                >
                    {foundGames.map(game => {
                        return <GameCard
                            gameLink={`${websiteRoutes.gameLaunch}?internalId=${game.internal_id}`}
                            id={game.Name}
                            key={game.internal_id}
                            itemSize={itemSize}
                            md={false}
                            picUrl={`url(${cdn}/game_img/sqr_mid/${game.Pic})`}
                        />
                    })}
                </Box>
            </Stack>}

        </Stack>
    </Dialog>
}