import React, { FC } from "react"
import {ProviderCard} from "../../casino/casino_grid/ProviderCard";
import {websiteRoutes} from "../../../features/routes/routes";
import {Box, useTheme} from "@mui/material";
import {getMediaCDN} from "../../../config";
import {useCommonMediaQueries} from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {usePlayGames} from "../../../features/play_games/usePlayGames";

export const ProvidersSection: FC = () => {
    const theme = useTheme()
    const gap = theme.spacing(1)
    const { mediumScreen: md} = useCommonMediaQueries()
    const itemSize = md ? `calc((100% - ${gap} * (3 - 1)) / 3)` : `calc((100% - ${gap} * (8 - 1)) / 8)`
    const cdn = getMediaCDN()


    const {providers} = usePlayGames()

    return <Box
        // width={!md ? `calc(840px + ${gap} * 7)` : `calc(315px + ${gap} * 2)`}
        display={'flex'}
        gap={gap}
        flexWrap={'wrap'}
    >
        {providers && providers.slice(0, 16).map(provider => {
            return <ProviderCard
                horizontalMode
                transparentBg
                contrastFilterDisabled
                providerLink={`${websiteRoutes.casino}?provider=${provider.provider_name}`}
                id={provider.provider_name}
                itemSize={itemSize}
                md={false}
                // picUrl={`${cdn}/provider_img/hor/${provider.provider_name}.png`}
                picUrl={`${cdn}/provider_img/hor/${provider.logo}`}
            />
        })}
    </Box>
}