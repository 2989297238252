export const chunkArray = <T>(array: T[], chunkSize: number): T[][] => {
    return Array.from({ length: Math.ceil(array.length / chunkSize) }, (_, index) => array.slice(index * chunkSize, index * chunkSize + chunkSize));
}


// Email validation function
export const validateEmail = (email: string, emailIsRequiredMess: string, notValidEmail: string) => {
    if (!email) {
        return `${emailIsRequiredMess}`;
    }
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailPattern.test(email)) {
        return `${notValidEmail}`;
    }
    return ''; // Empty string means validation passed
};

const stringDigitsDelimiter = (value: string): string => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}

export interface SeparatedDateTime {
    date: string
    time: string
}

export const separateDateTime = (dateTimeString: string): SeparatedDateTime => {
    const parts = dateTimeString.split(' ')
    parts[1] = parts[1].split(':').slice(0, 2).join(':')
    return {
        date: parts[0],
        time: parts[1]
    }
}


export const convertCentsToCurrencyString = (userBalance: number, withoutCents: boolean = false): string => {
    let result: string;
    const delimiter = ','

    const b = userBalance / 100

    if (b.toString().includes('.')) {
        const parts = b.toString().split('.')
        if (withoutCents) {
            result = parts[0]
        } else {
            if (parts[1].length < 2) {
                parts[1] = parts[1] + '0'
                if (parts[1].length < 2) {
                    parts[1] = parts[1] + '0'
                }
            } else if (parts[1].length > 2) {
                parts[1] = parts[1].slice(0, 2)
            }
            result = parts.join(delimiter)
        }
    } else {
        result = b.toString() + (withoutCents ? '' : `${delimiter}00`)
    }

    return stringDigitsDelimiter(result)
}

export const convertStringCurrencyToCents = (currency: string): number => {

    if (currency.replace(',', '.').includes('.')) {
        const parts = currency.replace(',', '.').split('.')
        parts[parts.length - 1] = parts[parts.length - 1].slice(0, 2).padEnd(2, '0')
        return parseInt(parts.join(''))
    } else {
        return parseInt(currency + '00')
    }
}

export const convertBalanceToCurrency = (exchangeRate: number, balance?: number) : number|undefined => {
    if (balance !== undefined) {
        return balance / exchangeRate
    }
    return undefined
}

export const currencyConvert = (balance: number, userCurrencyExchangeRate: number, convertedCurrencyExchangeRate: number) : number => {
    const dollarBalance = balance * userCurrencyExchangeRate
    return Math.round(dollarBalance / convertedCurrencyExchangeRate)
}

export const getNumberParam = (paramValue: string|null): number|null => {
    if (paramValue && !isNaN(Number(paramValue))) {
        return Number(paramValue)
    } else {
        return null
    }
}

/**
 * Converts Unix timestamp to a compact formatted local date and time string.
 * @param unixTime - Unix timestamp (in seconds).
 * @returns Formatted local date and time string in the format "DD.MM.YYYY HH:mm:ss".
 */
export const convertUnixToCompactLocaleDate = (unixTime: number): string => {
    // Create a new Date object using the provided Unix timestamp (converted to milliseconds).
    const date = new Date(unixTime * 1000);

    // Options for formatting the date.
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    };

    // Get the formatted date and time string using the specified options.
    const formattedDate = date.toLocaleString(undefined, options);

    // Extract day, month, year, and time components from the formatted string.
    const day = formattedDate.slice(0, 2);
    const month = formattedDate.slice(3, 5);
    const year = formattedDate.slice(6, 10);
    const time = formattedDate.slice(12);

    // Assemble the compact date and time string in the desired format.
    return `${day}.${month}.${year} ${time}`;
}

export const convertDateToUnixSeconds = (date: Date) => {
    return Math.floor(date.getTime() / 1000)
}

export const convertUnixDateToString = (time: number): string => {
    const dateObject = new Date(time * 1000)
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();

    return `${day}.${month}.${year}`;
}

export const convertDateToStringDate = (date: Date): string => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString();
    return `${day}-${month}-${year}`;
}

export const generateColorHex = (inputString: string): string => {
    const colorList: string[] = [
        "#3498db",
        "#e74c3c",
        "#2ecc71",
        "#f39c12",
        "#1abc9c",
        "#d35400",
        "#2c3e50",
        "#02c2fd",
        "#16a085",
        "#c0392b",
        "#7ab20a",
        "#f1c40f",
        "#2980b9",
        "#e67e22",
        "#34495e",
        "#9b59b6",
        "#1abc9c",
        "#e74c3c",
        "#3498db",
        "#de4504",
        "#f39c12",
        "#c0392b",
        "#16a085",
        "#f1c40f",
        "#8e44ad",
        "#44b604",
        "#d35400",
        "#2980b9",
        "#34495e"
        // Добавьте здесь больше цветов, если нужно
    ];

    const hash: number = Math.abs(hashCode(inputString));
    const index: number = hash % colorList.length;

    return colorList[index];
}

function hashCode(input: string): number {
    let hash = 0;
    if (input.length === 0) return hash;
    for (let i = 0; i < input.length; i++) {
        const char = input.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash = hash & hash; // Преобразование в 32-битное целое число
    }
    return hash;
}

export  const getTimeZoneOffset = (): number => {
    const now = new Date();
    const offsetInMinutes = now.getTimezoneOffset();
    const offsetInHours = offsetInMinutes / 60;
    return -offsetInHours;
}

const sundayStartTimeZones: string[] = [
    'America/New_York', 'America/Los_Angeles', 'America/Chicago', 'America/Denver', 'America/Phoenix',
    'America/Toronto', 'America/Vancouver', 'America/Montreal', 'America/Mexico_City',
    'Asia/Riyadh', 'Asia/Dubai', 'Asia/Kuwait', 'Asia/Bahrain', 'Asia/Doha', 'Asia/Qatar', 'Asia/Muscat',
    'Israel', 'Pacific/Honolulu'
];

// Check if the week starts on Sunday for the given time zone
const doesWeekStartOnSunday = (timeZone: string): boolean => {
    return sundayStartTimeZones.includes(timeZone);
}

// Function to determine the first day of the week based on the user's time zone
export const getWeekStartDayFromTimeZone = (): 0 | 1 => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // If the time zone is in the list, the week starts on Sunday
    if (doesWeekStartOnSunday(timeZone)) {
        return 0; // Sunday
    }

    return 1; // Monday by default
}