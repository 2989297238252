import React, {FC, useEffect, useMemo, useState} from "react";
import {CasinoGridView} from "./CasinoGridView";
import {CasinoGridExpandedProps} from "./CasinoGrid.interface";
import {useNavigate} from "react-router-dom";
import {Box, Button, LinearProgress, Stack, Typography, useTheme} from "@mui/material";
import {getMediaCDN} from "../../../config";
import {useCommonMediaQueries} from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {useFilterGames} from "./CasinoGrid.funcs";
import {GameCard} from "./GameCard";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {usePlayGames} from "../../../features/play_games/usePlayGames";
import {websiteRoutes} from "../../../features/routes/routes";
import {useLanguage} from "../../../features/localisation/useLanguage";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const CasinoGridExpanded: FC<CasinoGridExpandedProps> = ({title, mode, provider, gameType}) => {
    const { languagePack: {pack: {casino: lang, common: langCommon}, direction}} = useLanguage()
    const {providers} = usePlayGames()

    const navigate = useNavigate();
    const theme = useTheme()
    const [cutCount, setCutCount] = useState<number>(32)

    const { mediumScreen: md} = useCommonMediaQueries()
    const gap = theme.spacing(1)
    const itemSize = md ? `calc((100% - ${gap} * (3 - 1)) / 3)` : `calc((100% - ${gap} * (8 - 1)) / 8)`
    const cdn = getMediaCDN()


    const filteredGames = useFilterGames(mode, gameType, provider)
    
    const cuttedGamesList = useMemo(() => {
        return filteredGames.slice(0, cutCount)
    }, [cutCount, filteredGames])

    const progressPercentage = (cuttedGamesList.length / filteredGames.length) * 100;

    const localTitle = useMemo((): string => {
        if (mode === 'provider' && providers !== null) {
            const currentProvider = providers.find(p => p.provider_name === provider)
            if (currentProvider) {
                return currentProvider.title ?? title
            }
        }
        return title;
    }, [mode, provider, title, providers])

    const goBackOrHome = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/');
        }
    };

    useEffect(() => {
        setCutCount(32); // Сброс cutCount на начальное значение при изменении mode, provider или gameType
    }, [mode, provider, gameType]);

    return <CasinoGridView
        backButtonClick={goBackOrHome}
        title={localTitle}
    >
        <Box
            py={3}
            sx={{
                overflowX: 'auto',
            }}
        >
            <Box
                // width={!md ? `calc(840px + ${gap} * 7)` : `calc(315px + ${gap} * 2)`}
                display={'flex'}
                gap={gap}
                flexWrap={'wrap'}
            >
                {cuttedGamesList.map(game => {
                    return <GameCard
                        gameLink={`${websiteRoutes.gameLaunch}?internalId=${game.internal_id}`}
                        id={game.Name}
                        key={game.internal_id}
                        itemSize={itemSize}
                        md={false}
                        picUrl={`url(${cdn}/game_img/sqr_mid/${game.Pic})`}
                    />
                })}
            </Box>
        </Box>

        {cuttedGamesList.length < filteredGames.length && <Stack
            gap={3}
            alignItems={'center'}
        >
            <Button
                onClick={() => setCutCount(cutCount + 16)}
                variant={'outlined'}
                size={'large'}
                endIcon={direction === 'ltr' ? <ArrowForwardIcon/> : <ArrowBackIcon/>}
            >
                {langCommon.loadMore}
            </Button>
            <Typography>
                {lang.showedOf
                    .replace('%%a%%', `<strong>${cuttedGamesList.length}</strong>`)
                    .replace('%%b%%', `<strong>${filteredGames.length}</strong>`)
                    .split(/(<strong>.*?<\/strong>)/)
                    .map((part, index) =>
                        part.startsWith('<strong>') && part.endsWith('</strong>') ? (
                            <strong key={index}>
                                {part.replace('<strong>', '').replace('</strong>', '')}
                            </strong>
                        ) : (
                            part
                        )
                    )}
            </Typography>
            <LinearProgress
                value={progressPercentage}
                sx={{
                    width: '100%',
                    height: '8px',
                    borderRadius: 5,
                    backgroundColor: 'black',
                    '& .MuiLinearProgress-bar': {
                        borderRadius: 5,
                        backgroundColor: 'white',
                    },
            }}
                variant={'determinate'}
            />
        </Stack>}
    </CasinoGridView>
}