import { MoneyTransaction } from "./transactionModel";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {convertUnixToCompactLocaleDate} from "../common_funcs/common_funcs";

interface TransactionsState {
    transactions: MoneyTransaction[]
    pageSize: number
    currentPage: number
    totalRecords: number
    totalPages: number
    isTransactionsLoading: boolean
}

const initialState: TransactionsState = {
    transactions: [],
    pageSize: 10,
    currentPage: 1,
    totalRecords: 0,
    totalPages: 0,
    isTransactionsLoading: false
}

export const TransactionsSlice = createSlice({
    name: 'transactions',
    initialState,
    reducers: {
        setTransactions: (state, action: PayloadAction<MoneyTransaction[]>) => {
            state.transactions = action.payload.map(transaction => ({
                ...transaction,
                request_time: convertUnixToCompactLocaleDate(transaction.request_time_unix),
                respond_time: transaction.respond_time_unix !== null ? convertUnixToCompactLocaleDate(transaction.respond_time_unix) : null
            }))
        },
        clearTransactions: (state) => {
            state.transactions = []
        },
        setCurrentTransactionsPage: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload
        },
        setTotalTransactionsRecords: (state, action: PayloadAction<number>) => {
            state.totalRecords = action.payload
            state.totalPages = Math.ceil(action.payload / state.pageSize)
        },
        setTransactionsPageSize: (state, action: PayloadAction<number>) => {
            state.pageSize = action.payload
        },
        setIsTransactionsLoading: (state, action: PayloadAction<boolean>) => {
            state.isTransactionsLoading = action.payload
        }
    }
})

export default TransactionsSlice.reducer

export const {
    setTransactions,
    setIsTransactionsLoading,
    setTotalTransactionsRecords,
    setTransactionsPageSize,
    setCurrentTransactionsPage,
    clearTransactions
} = TransactionsSlice.actions