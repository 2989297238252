import React, {FC} from "react";
import {Page} from "../components/layout/Page";
import {useLogic} from "../components/dashboard/user_info/useLogic";
import {useUser} from "../features/user/useUser";
import {useLanguage} from "../features/localisation/useLanguage";
import {useCommonMediaQueries} from "../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {
    alpha,
    Button,
    Card,
    CircularProgress,
    Divider, InputLabelProps,
    Stack,
    SxProps,
    TextField,
    Theme,
    Typography, useTheme
} from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {validateEmail} from "../features/common_funcs/common_funcs";
import {ShowBalance} from "../components/balance/ShowBalance";
import {LoginFormModal} from "../components/authorization/login/LoginForm";

export const UserInfoPage: FC = () => {
    const { languagePack: { pack: {  userInfo: lang} } } = useLanguage()
    const { xxSmallScreen, smallScreen, mediumScreenMin, mediumScreen : md } = useCommonMediaQueries()
    const { user } = useUser()
    const {
        userInfoForm,
        isUserInfoUpdateLoading,
        email,
        emailError,
        setEmailError,
        setEmail,
        firstname,
        lastname,
        phone,
        setPhone,
        setLastname,
        setFirstname,
        handleSaveInfo,
        isChangePasswordLoading,
        currentPassword,
        passwordError,
        newPassword,
        retypeNewPasswordError,
        retypeNewPassword,
        setCurrentPassword,
        setNewPassword,
        setRetypeNewPassword,
        newPasswordError,
        handleUpdatePassword
    } = useLogic()
    const theme = useTheme();

    const inputLabelSlotProps: { inputLabel: Partial<InputLabelProps> } = {
        inputLabel: {
            shrink: true,
            sx: {
                '&.MuiInputLabel-shrink': {
                    transform: `translate(${theme.spacing(1.6)}, -20px)`,
                    fontSize: '0.8rem',
                    color: alpha('#fff', 0.6),
                },
                '&.Mui-focused': {
                    color: theme.palette.primary.main, // Использует основной цвет темы
                }
            },
        },
    };

    const inputSxProps: SxProps<Theme> = {
        mt: 2,
        '& .MuiOutlinedInput-notchedOutline legend': {
            width: 0,
        },
    };

    return <Page dashboardMode>
        <Stack
            maxWidth={'1280px'}
            width={'100%'}
            mx={'auto'}
            p={2}
            gap={2}
        >
        {user && <Stack padding={'8px'} gap={'12px'} direction={md ? 'column' : 'row'}>
            <Card sx={{flex: !md ? 1 : undefined}}>
                <Stack padding={xxSmallScreen ? '8px' : smallScreen ? '12px' : '18px'} gap={'16px'}>
                    <Typography fontSize={smallScreen ? '16px' : '26px' } component={'h2'} sx={{ userSelect: 'none'}}>
                        {lang.userInfoSection}
                    </Typography>

                    <Stack direction={'row'} gap={'14px'} alignItems={'center'}>
                        {mediumScreenMin && <AccountCircleIcon sx={{ color: alpha('#fff', 0.15), fontSize: '120px' }}/>}
                        <Stack gap={'10px'} width={'100%'}>
                            <Stack direction={'row'} alignItems={'center'} gap={'10px'}>
                                {smallScreen && <AccountCircleIcon sx={{ color: alpha('#fff', 0.15), fontSize: '32px' }}/>}
                                <Stack justifyContent={'center'}>
                                    <Typography fontSize={'10px'} fontWeight={200} lineHeight={1} sx={{ userSelect: 'none'}}>
                                        Login
                                    </Typography>
                                    <Typography fontSize={'14px'} lineHeight={1.2} sx={{ userSelect: 'none'}}>
                                        {user.user_name}
                                    </Typography>
                                </Stack>
                            </Stack>

                            <Stack gap={'6px'} direction={xxSmallScreen ? 'column' : 'row'} alignSelf={smallScreen ? undefined : 'start'}>
                                <ShowBalance dark={false} justify={'start'} minWidth={mediumScreenMin ? '180px' : undefined} balance={user.user_balance} currency={'TND'}/>
                                {/*<ShowBalance justify={'start'} minWidth={mediumScreenMin ? '180px' : undefined} balance={user.user_bonus_balance} currency={'Bonus'}/>*/}
                            </Stack>

                            {/*<Stack direction={'row'} gap={'6px'} justifyContent={'end'}>*/}
                            {/*    <Button sx={{textTransform: 'none', flex: xxSmallScreen ? 1 : undefined}} variant={'contained'}>Deposit money</Button>*/}
                            {/*    <Button sx={{textTransform: 'none', flex: xxSmallScreen ? 1 : undefined}} variant={'outlined'}>Withdraw money</Button>*/}
                            {/*</Stack>*/}
                        </Stack>
                    </Stack>

                    <Divider/>

                    <Stack gap={'8px'} direction={'column'}>
                        <TextField
                            sx={{...inputSxProps, flex: 1}}
                            disabled={isUserInfoUpdateLoading}
                            label={userInfoForm.firstnameFieldLabel}
                            value={firstname}
                            onChange={(e) => setFirstname(e.target.value)}
                            slotProps={inputLabelSlotProps}
                        />
                        <TextField
                            sx={{...inputSxProps, flex: 1}}
                            disabled={isUserInfoUpdateLoading}
                            label={userInfoForm.lastnameFieldLabel}
                            value={lastname}
                            onChange={(e) => setLastname(e.target.value)}
                            slotProps={inputLabelSlotProps}
                        />
                        <TextField
                            sx={{...inputSxProps, flex: 1}}
                            disabled={isUserInfoUpdateLoading}
                            label={userInfoForm.emailFieldLabel}
                            type={'email'}
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value)
                                const emailValidationResult = validateEmail(e.target.value, userInfoForm.emailIsRequiredMess, userInfoForm.notValidEmail);
                                setEmailError(emailValidationResult);
                            }}
                            error={Boolean(emailError)}
                            helperText={emailError}
                            slotProps={inputLabelSlotProps}
                        />
                        <TextField
                            sx={{...inputSxProps, flex: 1}}
                            disabled={isUserInfoUpdateLoading}
                            label={userInfoForm.phoneFieldLabel}
                            type={'tel'}
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            slotProps={inputLabelSlotProps}
                        />
                    </Stack>

                    <Button endIcon={isUserInfoUpdateLoading ? <CircularProgress size={16}/> : <></>}
                            disabled={Boolean(emailError) || isUserInfoUpdateLoading}
                            variant={'contained'} onClick={handleSaveInfo}>{userInfoForm.saveInfoBTN}</Button>
                </Stack>
            </Card>
            <Card sx={{flex: !md ? 1 : undefined}}>
                <Stack padding={xxSmallScreen ? '8px' : smallScreen ? '12px' : '18px'} gap={'16px'}>
                    <Typography fontSize={smallScreen ? '16px' : '26px' } component={'h2'} sx={{ userSelect: 'none'}}>
                        {lang.changePasswordSection}
                    </Typography>
                    <TextField
                        sx={inputSxProps}
                        disabled={isChangePasswordLoading}
                        label={userInfoForm.currentPassFieldLabel}
                        type={'password'}
                        value={Boolean(currentPassword) ? currentPassword : ''}
                        error={Boolean(passwordError)}
                        helperText={passwordError}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        slotProps={inputLabelSlotProps}
                    />
                    <TextField
                        sx={inputSxProps}
                        disabled={isChangePasswordLoading}
                        label={userInfoForm.newPassFieldLabel}
                        type={'password'}
                        value={Boolean(newPassword) ? newPassword : ''}
                        error={Boolean(newPasswordError)}
                        helperText={newPasswordError}
                        onChange={(e) => setNewPassword(e.target.value)}
                        slotProps={inputLabelSlotProps}
                    />
                    <TextField
                        sx={inputSxProps}
                        disabled={isChangePasswordLoading}
                        label={userInfoForm.retypePassFieldLabel}
                        type={'password'}
                        value={Boolean(retypeNewPassword) ? retypeNewPassword : ''}
                        error={Boolean(retypeNewPasswordError)}
                        helperText={retypeNewPasswordError}
                        onChange={(e) => setRetypeNewPassword(e.target.value)}
                        slotProps={inputLabelSlotProps}
                    />

                    <Button disabled={isChangePasswordLoading || (!Boolean(currentPassword) || !Boolean(newPassword) || !Boolean(retypeNewPassword) || Boolean(passwordError) || Boolean(newPasswordError) || Boolean(retypeNewPasswordError))}
                            endIcon={isChangePasswordLoading ? <CircularProgress size={16}/> : <></>}
                             variant={'contained'} onClick={handleUpdatePassword}>
                        {userInfoForm.updatePassBTN}
                    </Button>
                </Stack>
            </Card>
        </Stack>}
        </Stack>

        {user === null && <LoginFormModal/>}
    </Page>
}