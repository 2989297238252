import {Page} from "../components/layout/Page";
import {Stack} from "@mui/material";
import {TopLinksCards} from "../components/layout/top_links/TopLinksCards";
import {CarouselSlider} from "../components/common/carousel/CarouselSlider";
import Slide1En from '../../assets/carousel_pics/slide_1_en_1729077041.png'
import Slide2En from '../../assets/carousel_pics/slide_3_en_1726060063.jpeg'
import Slide3En from '../../assets/carousel_pics/slide_3_en_1729502724.jpeg'
import Slide1 from '../../assets/carousel_pics/slide_4_fr_1729514657.jpeg'
import Slide2 from '../../assets/carousel_pics/slide_5_fr_1729514678.jpeg'
import Slide3 from '../../assets/carousel_pics/slide_6_fr_1729514697.jpeg'
import {websiteRoutes} from "../features/routes/routes";
import {useLanguage} from "../features/localisation/useLanguage";
import {Lang} from "../features/localisation/useLanguageInterface";

export const HomePage = () => {
    const { languagePack: {lang}} = useLanguage()

    const picUrls = [
        {pic: Slide1, link: websiteRoutes.pragmatic},
        {pic: Slide2, link: websiteRoutes.evolution},
        {pic: Slide3, link: websiteRoutes.amatic},
    ]

    const picUrlsEn = [
        {pic: Slide1En, link: websiteRoutes.pragmatic},
        {pic: Slide2En, link: websiteRoutes.evolution},
        {pic: Slide3En, link: websiteRoutes.amatic},
    ]

    return <Page>
        <Stack>
            <CarouselSlider
                picUrls={lang === Lang.EN ? picUrlsEn : picUrls}
            />
            <TopLinksCards/>
        </Stack>
    </Page>
}