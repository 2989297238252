import { iUseLanguage, Lang } from "./useLanguageInterface";
import { useCallback } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { changeLang } from "./LanguageSlice";
import { addNewError } from "../ApiErrors/errorSlice";
import { getLangPackByName } from "./languageFuncs";
import { useSnackbar, VariantType } from "notistack";
import axios, { AxiosError } from "axios";
import langStorage from "../localStorage/langStorage";
import {ApiError} from "../common_funcs/apiResponseModels";
import {enLang} from "./packs/enLang";
import {frLang} from "./packs/frLang";
import {arLang} from "./packs/arLang";

export const useLanguage = (): iUseLanguage => {
    const dispatch = useAppDispatch()
    const languagePack = useAppSelector(state => state.lang.languagePack)
    const { enqueueSnackbar } = useSnackbar();

    const sendSnackBar = useCallback((variant: VariantType, message: string) => {
        enqueueSnackbar(message, { variant });
    }, [enqueueSnackbar])

    const switchLanguageFromStore = useCallback(() => {
        const lang = langStorage.getLang()
        if (lang) {
            const langPack = getLangPackByName(lang)
            dispatch(changeLang(langPack))
            document.documentElement.lang = langPack.langShort
            document.documentElement.setAttribute('dir', langPack.direction)
        }
    }, [dispatch])

    const switchLanguage = useCallback((lang: Lang) => {
        const langPack = getLangPackByName(lang)
        dispatch(changeLang(langPack))
        langStorage.setLang(lang)
        document.documentElement.lang = langPack.langShort
        document.documentElement.setAttribute('dir', langPack.direction)
    }, [dispatch])

    const setLocalizedError = useCallback((error: ApiError) => {
        const errorMessages = JSON.parse(JSON.stringify(languagePack.pack.errorMessages))
        const errorCode = error.code.toString();

        if (errorMessages.hasOwnProperty(errorCode)) {
            dispatch(addNewError({
                ...error,
                message: errorMessages[errorCode]
            } as ApiError))
        } else {
            dispatch(addNewError(error))
        }
    }, [dispatch, languagePack.pack.errorMessages])

    const handleNetworkErrors = useCallback((error: Error | AxiosError) => {
        if (axios.isAxiosError(error)) {
            if (error.code === "ERR_NETWORK") {
                sendSnackBar('error', languagePack.pack.internalErrorMessages.networkError)
            } else {
                console.error('axios error', error)
            }
        } else {
            console.error('not axios error', error)
        }
    }, [languagePack, sendSnackBar])

    const getLangPacks = () => {
        return [enLang, frLang, arLang]
    }

    return { switchLanguageFromStore, languagePack, switchLanguage, setLocalizedError, handleNetworkErrors, getLangPacks }
}