import {FC, useState} from "react";
import {Button} from "@mui/material";
import {LoginFormModal} from "./LoginForm";
import { useUser } from "../../../features/user/useUser";
import {useLanguage} from "../../../features/localisation/useLanguage";

export const LoginActivator: FC = () => {
    const {token, user} = useUser()
    const { languagePack: {pack: {authorization: lang}}} = useLanguage()

    const [loginFormOpened, setLoginFormOpened] = useState<boolean>(false)

    return !Boolean(token && user) ? <>
        <Button
            onClick={() => setLoginFormOpened(true)}
            variant={'contained'}
            sx={{
                textTransform: 'uppercase'
            }}
        >
            {lang.loginBTN}
        </Button>

        {loginFormOpened && <LoginFormModal close={() => setLoginFormOpened(false)}/>}
    </> : null
}