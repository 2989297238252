import React, {FC, useState} from "react";
import {useLoginFormLogic} from "../../../features/user/useLoginFormLogic";
import {Box, Button, CircularProgress, IconButton, InputAdornment, Stack, TextField} from "@mui/material";
import {ModalInterface} from "../../common/modal_wrapper/model";
import {ModalWrapper} from "../../common/modal_wrapper/ModalWrapper";
import { AccountCircle, Visibility, VisibilityOff, Close } from '@mui/icons-material';
import {useLanguage} from "../../../features/localisation/useLanguage";

export const LoginForm: FC<ModalInterface> = (props) => {
    const { languagePack: {pack: {authorization: lang}}} = useLanguage()
    const {
        username,
        password,
        usernameFieldHandler,
        passwordFieldHandler,
        submitDisabled,
        isLogInLoading,
        sendLogin
    } = useLoginFormLogic()

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleLoginSubmit = () => {
        sendLogin(username, password, props.close)
    }


    return <Stack>
        <TextField
            label={lang.usernameLabel}
            slotProps={{
                input: {
                    startAdornment: (
                        <InputAdornment position="start">
                            <AccountCircle sx={{ color: 'black' }} />
                        </InputAdornment>
                    ),
                },
            }}
            fullWidth
            margin="normal"
            value={username}
            onChange={usernameFieldHandler}
        />
        <TextField
            label={lang.passwordLabel}
            type={showPassword ? 'text' : 'password'}
            slotProps={{
                input: {
                    startAdornment: (
                        <InputAdornment position="start">
                            <Box width="24px" />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                sx={{ color: 'black' }}
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                    ),
                },
            }}
            fullWidth
            margin="normal"
            value={password}
            onChange={passwordFieldHandler}
        />
        <Button
            disabled={submitDisabled()}
            sx={{
                mt: '12px',
                backgroundColor: 'black',
                color: 'white',
                textTransform: 'uppercase',
                p: '8px',
                '&.Mui-disabled': {
                    backgroundColor: '#a4a4a4',
                    color: 'black',
                },
            }}
            variant={'contained'}
            endIcon={isLogInLoading && <CircularProgress size={'14px'} color={'inherit'}/>}
            onClick={handleLoginSubmit}
        >
            {lang.loginBTN}
        </Button>
    </Stack>
}


export const  LoginFormModal: FC<ModalInterface> = (props) => {
    const { languagePack: {pack: {authorization: lang}}} = useLanguage()
    return <ModalWrapper
        component={LoginForm}
        componentProps={{
            ...props, dialogTitle: <Box
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
                textTransform={'uppercase'}
            >
                {lang.loginTitle}
                {props.close !== undefined && <Button
                    onClick={props.close}
                    sx={{
                        minWidth: '36px',
                        maxWidth: '36px',
                        height: '36px',
                        justifyContent: 'center',
                        display: 'flex',
                        backgroundColor: 'white',
                    }}
                    variant={'outlined'}
                >
                    <Close/>
                </Button>}
            </Box>
        }}
    />
}