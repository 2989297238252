import {FC} from "react";
import {Box, Typography, useTheme} from "@mui/material";
import {Link} from "react-router-dom";

export const LinkCard: FC<{link: string, title: string, picUrl: string, size: string}> = ({link, title, picUrl, size}) => {
    const theme = useTheme()

    return <Box
        width={size}
        sx={{
            aspectRatio: '4 / 3',
        }}
    >
        <Box
            width={'100%'}
            height={'100%'}
            borderRadius={'6px'}
            sx={theme => ({
                backgroundImage: `url(${picUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                '& a': {
                    textDecoration: 'none',
                    color: theme.palette.text.primary
                },
            })}
            boxShadow={'0 8px 30px #fff1'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'end'}
            p={2}
        >
            <Link to={link}>
                <Box
                   border={'1px solid white'}
                   p={theme.spacing(1,2)}
                   borderRadius={'8px'}
                   sx={{
                       backgroundColor: theme.palette.background.default,
                       transition: 'opacity 0.1s ease-in-out',
                       '&:hover': {
                           opacity: 0.55,

                       }
                   }}
                >
                    <Typography
                        textTransform={'uppercase'}
                        textAlign={'center'}
                    >
                        {title}
                    </Typography>
                </Box>
            </Link>

        </Box>
    </Box>
}