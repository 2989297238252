import { FC } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {useTransactions} from "../../features/transactions/useTransactions";
import {useLanguage} from "../../features/localisation/useLanguage";
import {convertCentsToCurrencyString} from "../../features/common_funcs/common_funcs";
import {getColorForTransactionApproveStatus} from "./transaction_funcs";

export const TransactionsTable: FC = () => {
    const { languagePack: {pack: { transactionsTable }}} = useLanguage()
    const { transactions } = useTransactions()

    return <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>{transactionsTable.amountTableHeader}</TableCell>
                    <TableCell align="right">{transactionsTable.methodTableHeader}</TableCell>
                    <TableCell align="right">{transactionsTable.statusTableHeader}</TableCell>
                    <TableCell align="right">{transactionsTable.depositWithdrawTableHeader}</TableCell>
                    <TableCell align="right">{transactionsTable.dateTimeTableHeader}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {transactions.map((transaction, index) => (
                    <TableRow
                        key={transaction.transfer_id + '-transfer-' + index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                        <TableCell component="th" scope="row">
                            {convertCentsToCurrencyString(transaction.transfer_amount)}
                        </TableCell>
                        <TableCell align="right">{transaction.money_transfer_method_name}</TableCell>
                        <TableCell style={{ color: getColorForTransactionApproveStatus(transaction.transfer_status) }}
                                   align="right">{transaction.transfer_status}</TableCell>
                        <TableCell align="right">{transaction.transfer_type}</TableCell>
                        <TableCell align="right">{transaction.respond_time ?? transaction.request_time}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
}

