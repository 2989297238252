import {useLanguage} from "../../features/localisation/useLanguage";
import {useMemo} from "react";
import {LanguageSwitcherHook} from "./LanguageSwitcher.interface";

export const useLanguageSwitcherLogic = () : LanguageSwitcherHook => {
    const { languagePack: currentLanguage, getLangPacks, switchLanguage } = useLanguage();
    const langPacks = useMemo(() => getLangPacks(), [getLangPacks])

    const currentLang = langPacks.find((lang) => lang.lang === currentLanguage.lang);
    const filteredLangs = langPacks.filter((lang) => lang.lang !== currentLanguage.lang);

    return {switchLanguage, currentLang, filteredLangs}
}