import React, {FC, useEffect} from "react";
import {Page} from "../components/layout/Page";
import {useLocation} from "react-router-dom";
import {useAppDispatch} from "../store";
import {useTransactions} from "../features/transactions/useTransactions";
import {useUser} from "../features/user/useUser";
import {useCommonMediaQueries} from "../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {getNumberParam} from "../features/common_funcs/common_funcs";
import {useLanguage} from "../features/localisation/useLanguage";
import {setTransactionsPageSize} from "../features/transactions/transactionsSlice";
import {Stack, Typography} from "@mui/material";
import {PageSizeSwitcher} from "../components/common/page_size_switcher/PageSizeSwitcher";
import {TransactionItem} from "../components/transactions/TransactionItem";
import {TransactionsTable} from "../components/transactions/TransactionsTable";
import {Paginator} from "../components/common/paginator/Paginator";
import {LoginFormModal} from "../components/authorization/login/LoginForm";

export const TransactionsPage: FC = () => {
    const { languagePack: { pack: {navigation: lang}} } = useLanguage()
    const location = useLocation()
    const dispatch = useAppDispatch()
    const { pageSize, currentPage, getTransactions, transactions, clearTransactions, totalPages } = useTransactions()
    const { user, token } = useUser()
    const { mediumScreen} = useCommonMediaQueries()

    useEffect(() => {
        return () => {
            clearTransactions()
        }
    }, [ clearTransactions ])

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search)
        const page: number|null = getNumberParam(searchParams.get('page'))
        const itemsOnPage: number|null = getNumberParam(searchParams.get('pageSize'))
        if ((itemsOnPage && itemsOnPage !== pageSize) && (page && page !== currentPage)) {
            getTransactions(page, itemsOnPage)
        } else if ((itemsOnPage && itemsOnPage !== pageSize) && transactions.length === 0) {
            getTransactions(1, itemsOnPage)
        } else if (itemsOnPage && itemsOnPage !== pageSize) {
            dispatch(setTransactionsPageSize(itemsOnPage))
            getTransactions(1, itemsOnPage)
        } else if (page && page !== currentPage) {
            getTransactions(page)
        } else if (transactions.length === 0) {
            getTransactions(1)
        } else if (!Boolean(itemsOnPage) && !Boolean(page) && (pageSize !== 30 || currentPage !== 1) && transactions.length !== 0) {
            getTransactions(1, 30)
        }
    }, [currentPage, transactions, dispatch, getTransactions, location.search, pageSize])

    return <Page dashboardMode>
        <Stack
            maxWidth={'1280px'}
            width={'100%'}
            mx={'auto'}
            p={2}
            gap={2}
        >
            {user &&  token && <Stack padding={'16px'} gap={'12px'}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <Typography fontSize={'28px'}>
                        {lang.transactions}
                    </Typography>
                    <PageSizeSwitcher pageSize={pageSize}/>
                </Stack>
                {mediumScreen ? transactions.map(transaction => <TransactionItem currency={user?.user_currency}
                                                                                 key={transaction.uuid}
                                                                                 transactionItem={transaction}/>)
                    : <TransactionsTable/>}
                <Stack direction={'row'} justifyContent={'end'}>
                    <Paginator totalPages={totalPages} currentPage={currentPage}/>
                </Stack>
            </Stack>}
        </Stack>

        {user === null && <LoginFormModal/>}
    </Page>
}