import {FC} from "react";
import {Box, Stack, Typography} from "@mui/material";
import EighteenPlusIcon from "../../svg_icons/EighteenPlusIcon";
import {useCommonMediaQueries} from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {useLanguage} from "../../../features/localisation/useLanguage";

export const AgeRestrictionBlock: FC<{flex?: number}> = ({flex}) => {
    const { languagePack: {pack: {footer: lang}}} = useLanguage()
    const {mediumScreen: md} = useCommonMediaQueries()

    return <Stack flex={flex} gap={2}>
        <Box
            display={'flex'}
            justifyContent={!md ? 'flex-start' : 'center'}
            gap={3}
        >
            <EighteenPlusIcon sx={{fontSize: '44px', color: 'white'}}/>
            <Box
                maxWidth={'108px'}
                width={'108px'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                border={'2px solid white'}
                borderRadius={'8px'}
            >
                <Typography
                    textTransform={'uppercase'}
                    textAlign={'center'}
                    fontSize={12}
                    lineHeight={'16px'}
                    fontWeight={600}
                    sx={{textWrap: 'wrap'}}
                >
                    {lang.playResponsibly}
                </Typography>
            </Box>
        </Box>

        <Typography fontSize={14} lineHeight={'21px'}>
            {lang.ageRestriction}
        </Typography>
    </Stack>
}