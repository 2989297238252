import { AxiosRequestConfig } from "axios";

const axiosConfig : AxiosRequestConfig<FormData> = {
    headers: {
        "Content-type": `multipart/form-data;`
    },
    responseType: 'json'
}

const Config = {
    localStoragePrefix: 'TnsCasino_',
    axiosConfig
}

export const getApiHost = () => {
    return process.env.REACT_APP_API_HOST
}

export const getCurrentDomain = () => {
    return process.env.REACT_APP_CURRENT_DOMAIN
}

export const getMediaCDN = () => {
    return process.env.REACT_APP_MEDIA_CDN
}

export default Config