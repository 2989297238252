import React, {FC, useState} from "react";
import {Button, useTheme} from "@mui/material";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {ConfirmationDialog} from "../../common/confirmation_dialog/ConfirmationDialog";
import {useUser} from "../../../features/user/useUser";
import {useLanguage} from "../../../features/localisation/useLanguage";

export const LogoutActivator: FC = () => {
    const { languagePack: {pack: {authorization: lang}}} = useLanguage()
    const [logOutOpened, setLogOutOpened] = useState<boolean>(false)
    const {logOut, user, token} = useUser()
    const theme = useTheme()

    return token && user ? <>
        <Button
            onClick={() => setLogOutOpened(true)}
            sx={{
                minWidth: '36px',
                maxWidth: '36px',
                height: '36px',
                justifyContent: 'center',
                display: 'flex',
                backgroundColor: 'transparent',
                borderColor: theme.palette.primary.main,
                color: theme.palette.primary.main
            }}
            variant={'outlined'}
        >
            <ExitToAppIcon/>
        </Button>


        {logOutOpened && <ConfirmationDialog
            message={lang.confirmLogOutMessage}
            close={() => setLogOutOpened(false)}
            onConfirm={logOut}
        />}
    </> : null
}