import {FC} from "react";
import {Box} from "@mui/material";
import playIcon from "../../../../assets/play.svg";
import {Link} from "react-router-dom";

export const GameCard: FC<{itemSize: string, md: boolean, picUrl: string, id: string, gameLink: string}> = ({itemSize, md, picUrl, id, gameLink}) => {
    return <Box
        id={id}
        flex={!md ? `0 0 ${itemSize}` : `0 0 105px`}
    >
        <Link to={gameLink}>
            <Box
                borderRadius={'4px'}
                width={'100%'}
                boxSizing={'border-box'}
                sx={{
                    backgroundColor: 'lightgray',
                    aspectRatio: '1 / 1',
                    backgroundImage: picUrl,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    cursor: 'pointer',
                    position: 'relative',
                    overflow: 'hidden',
                    '&:hover': {
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        },
                        '& .play-icon': {
                            opacity: 1,
                        },
                    },
                }}
            >
                <Box
                    className="play-icon"
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        opacity: 0,
                        transition: 'opacity 0.3s',
                        backgroundImage: `url(${playIcon})`,
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        width: '50%',
                        height: '50%',
                    }}
                />
            </Box>
        </Link>
    </Box>
}