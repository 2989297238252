/*
Last modified: 2024-10-12
 */
import { FC } from "react";
import {Stack, Typography, useTheme} from "@mui/material";
import {useCommonMediaQueries} from "../../features/common_funcs/mediaQueries/useCommonMediaQueries";

interface MediaqueryIndicatorProps {
    position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'
}

export const MediaqueryIndicator: FC<MediaqueryIndicatorProps> = ({position = 'top-right'}) => {
    const theme = useTheme()
    const { xxSmallScreen, smallScreen, mediumScreen, largeScreen, xLargeScreen } = useCommonMediaQueries()
    const localTesting = process.env.REACT_APP_ENVIRONMENT === 'live'

    const backgroundColor = xxSmallScreen ? '#e8052f' : smallScreen ? '#fd6900' : mediumScreen ? '#ffc400' : largeScreen ?
        '#96da21' : xLargeScreen ? '#00d9ff' : '#3a55de'

    const textColor = theme.palette.getContrastText(backgroundColor)

    const maxZIndex = Math.max(
        theme.zIndex.appBar,
        theme.zIndex.drawer,
        theme.zIndex.modal,
        theme.zIndex.tooltip
    );

    return localTesting ? <Stack
        zIndex={maxZIndex + 1}
        padding={'2px'}
        borderRadius={'4px'}
        minWidth={'30px'}
        alignItems={'center'}
        position={'fixed'}
        top={position !== undefined && ['top-left', 'top-right'].includes(position) ? 0 : undefined}
        bottom={position !== undefined && ['bottom-left', 'bottom-right'].includes(position) ? 0 : undefined}
        left={position !== undefined && ['top-left', 'bottom-left'].includes(position) ? 0 : undefined}
        right={position !== undefined && ['top-right', 'bottom-right'].includes(position) ? 0 : undefined}
        sx={{
            backgroundColor: backgroundColor,
            opacity: 0.8,
            '&:hover': {
                opacity: 1,
            },
            color: textColor
        }}>
        <Typography fontSize={'10px'} lineHeight={1} color={'inherit'}>
            {xxSmallScreen ? 'XS' : smallScreen ? 'SM' : mediumScreen ? 'MD' : largeScreen ? 'LG' : xLargeScreen ? 'XL' : 'XXL'}
        </Typography>
        <Typography fontSize={'8px'} lineHeight={1} color={'inherit'}>
            {xxSmallScreen ? '450' : smallScreen ? '767' : mediumScreen ? '960' : largeScreen ? '1200' : xLargeScreen ? '1536' : '1536+'}
        </Typography>
    </Stack> : <></>
}