import {useLanguage} from "./useLanguage";
import {useEffect, useState} from "react";

export const useLanguageWatcher = () => {
    const { switchLanguageFromStore } = useLanguage()
    const [justMounted, setJustMounted] = useState(true);

    useEffect(() => {
        if (justMounted) {
            switchLanguageFromStore()
            setJustMounted(false)
        }
    }, [justMounted, switchLanguageFromStore])
}