import {FC} from "react";
import {Box} from "@mui/material";
import { Link } from "react-router-dom";
import {ProviderCardProps} from "./ProviderCard.interface";

export const ProviderCard: FC<ProviderCardProps> = ({itemSize, providerLink, picUrl, id, md, horizontalMode, transparentBg, contrastFilterDisabled}) => {
    return <Box
        borderRadius={'4px'}
        id={id}
        flex={!md ? `0 0 ${itemSize}` : `0 0 105px`}
        sx={{
            backgroundColor: transparentBg ? 'transparent' : 'black',
            transition: 'background-color 0.4s ease-in-out',
            '&:hover': {
                backgroundColor: 'transparent',
            },
        }}
    >
        <Link to={providerLink}>
            <Box

                width={'100%'}
                boxSizing={'border-box'}
                sx={{
                    filter: contrastFilterDisabled ? undefined : 'contrast(50%)',
                    transition: 'filter 0.4s ease-in-out',
                    aspectRatio: horizontalMode ? '2 / 1' : '1 / 1',
                    backgroundImage: `url(${picUrl})`,
                    backgroundSize: '80%',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    cursor: 'pointer',
                    position: 'relative',
                    overflow: 'hidden',
                    '&:hover': {
                        filter: 'contrast(100%)',
                    },
                }}
            >
            </Box>
        </Link>
    </Box>
}