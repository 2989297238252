import React, {FC} from "react";
import {Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography} from "@mui/material";
import {InfoColumn} from "./FooterInfoBlock.interface";
import {websiteRoutes} from "../../../features/routes/routes";
import { Link } from "react-router-dom";
import {AgeRestrictionBlock} from "./AgeRestrictionBlock";
import {useCommonMediaQueries} from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useLanguage} from "../../../features/localisation/useLanguage";

export const FooterInfoBlock: FC = () => {
    const { languagePack: {pack: {footer: {footerColumns: {casino: casinoLang, sports: sportsLang, legal: legalLang, help: helpLang, contactUs: contactUsLang}}}}} = useLanguage()
    const {mediumScreen: md} = useCommonMediaQueries()
    const infoColumns: InfoColumn[] = [
        {
            title: casinoLang.title,
            items: [
                {title: casinoLang.featured, link: websiteRoutes.featured},
                {title: casinoLang.new, link: websiteRoutes.new},
                {title: casinoLang.slots, link: websiteRoutes.slots},
                {title: casinoLang.crash, link: websiteRoutes.crash},
                {title: casinoLang.pragmatic, link: websiteRoutes.pragmatic},
                {title: casinoLang.providers, link: websiteRoutes.providers},
                {title: casinoLang.gameShows, link: websiteRoutes.gameShows},
                {title: casinoLang.roulette, link: websiteRoutes.roulette},
                {title: casinoLang.blackjack, link: websiteRoutes.blackjack},
                {title: casinoLang.tableGames, link: websiteRoutes.tableGames},
                {title: casinoLang.otherGames, link: websiteRoutes.otherGames},
            ]
        },
        {
            title: sportsLang.title,
            items: [
                {title: sportsLang.preliveEvents, link: websiteRoutes.sports},
                {title: sportsLang.liveEvents, link: websiteRoutes.liveSports},
                {title: sportsLang.football, link: websiteRoutes.football},
                {title: sportsLang.basketball, link: websiteRoutes.basketball},
                {title: sportsLang.tennis, link: websiteRoutes.tennis}
            ]
        },
        {
            title: legalLang.title,
            items: [
                {title: legalLang.termsNConditions, link: websiteRoutes.termsNConditions},
                {title: legalLang.privacyPolicy, link: websiteRoutes.privacyPolicy},
                {title: legalLang.responsibleGaming, link: websiteRoutes.responsibleGaming},
            ]
        },
        {
            title: helpLang.title,
            items: [
                {title: helpLang.aboutUs, link: websiteRoutes.aboutUs},
            ]
        },
        {
            title: contactUsLang.title,
            items: [
                {title: 'Facebook', link: 'https://www.facebook.com/people/Silverbet365/61566828065243/', isExternalLink: true},
                {title: 'Devenir partenaire / ولي وكيل', link: 'https://wa.me/12342603083', isExternalLink: true},
                {title: 'Déposer des fonds / إيداع رصيد', link: 'https://wa.me/12342603083', isExternalLink: true},
                {title: 'Termes et conditions / الشروط والأحكام', link: websiteRoutes.contactTermsNConditions},
            ]
        }
    ]

    return <Box
        gap={2}
        display={'flex'}
        flexDirection={md ? 'column' : 'row'}
        sx={theme => ({
            '& a': {
                textDecoration: 'none',
                color: theme.palette.text.primary },
        })}
    >
        {infoColumns.map(col => md?
            <Accordion
                key={`column-${col.title}`}
                sx={{
                    backgroundColor: 'black',
                    border: '1px solid white',
                    borderRadius: '8px !important',
                    color: 'white',
                    '&:before': {
                        display: 'none',
                    },
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                        padding: '8px',
                        minHeight: 0,
                        '& .MuiAccordionSummary-content': {
                            margin: '0',
                        },
                        '&.Mui-expanded': {
                            minHeight: 0,
                        },
                    }}
                >
                    <Typography fontWeight={600}>
                        {col.title.toUpperCase()}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        padding: '8px', // Уменьшаем отступы
                    }}
                >
                    <Stack
                        gap={1}
                    >
                        {col.items.map(item => <Link
                            key={`item-${item.title}`}
                            to={item.link}
                            target={item.isExternalLink ? '_blank' : undefined}>
                            <Typography>
                                {item.title}
                            </Typography>
                        </Link>)}
                    </Stack>
                </AccordionDetails>
            </Accordion>
            :
            <Stack
            flex={1}
            key={`column-${col.title}`}
            gap={1}
        >
            <Typography fontWeight={600}>
                {col.title.toUpperCase()}
            </Typography>
            {col.items.map(item => <Link
                key={`item-${item.title}`}
                to={item.link}
                target={item.isExternalLink ? '_blank' : undefined}>
                <Typography>
                    {item.title}
                </Typography>
            </Link>)}
        </Stack>)}

        <AgeRestrictionBlock flex={2}/>
    </Box>
}