import {FC} from "react";
import {Box} from "@mui/material";
import {NavigatorTopItem} from "./NavigatorTopItem";
import {websiteRoutes} from "../../../features/routes/routes";
import {getNormalizedPath} from "../../../features/common_funcs/urlUtils";
import { useLocation } from "react-router-dom";
import {useLanguage} from "../../../features/localisation/useLanguage";

export const NavigatorTop: FC<{dashboardMode?: boolean}> = ({dashboardMode = false}) => {
    const { languagePack: {pack: {navigation: lang}}} = useLanguage()
    const location = useLocation();

    const isActivePath = (paths: string | string[]): boolean => {
        const normalizedPaths = getNormalizedPath(paths)
        return normalizedPaths.includes(location.pathname.replace(/\/$/, ''));
    };


    return <Box
        component={'nav'}
        display={'flex'}
        gap={1}
    >
        {!dashboardMode ? <>
            <NavigatorTopItem
                title={lang.home}
                link={websiteRoutes.home}
                active={isActivePath(websiteRoutes.home)}
            />
            <NavigatorTopItem
                title={lang.sports}
                link={websiteRoutes.sports}
                active={isActivePath(websiteRoutes.sports)}
            />
            <NavigatorTopItem
                title={lang.liveSports}
                link={websiteRoutes.liveSports}
                active={isActivePath(websiteRoutes.liveSports)}
            />
            <NavigatorTopItem
                title={lang.casino}
                link={websiteRoutes.casino}
                active={isActivePath(websiteRoutes.casino)}
            />
            <NavigatorTopItem
                title={lang.liveCasino}
                link={websiteRoutes.liveCasino}
                active={isActivePath(websiteRoutes.liveCasino)}
            />
            <NavigatorTopItem
                title={lang.virtuals}
                link={websiteRoutes.virtuals}
                active={isActivePath(websiteRoutes.virtuals)}
            />
        </>
        :
        <>
            <NavigatorTopItem
                title={lang.userInfo}
                link={websiteRoutes.userInfo}
                active={isActivePath(websiteRoutes.userInfo)}
            />
            <NavigatorTopItem
                title={lang.transactions}
                link={websiteRoutes.transactions}
                active={isActivePath(websiteRoutes.transactions)}
            />
            <NavigatorTopItem
                title={lang.gamesHistory}
                link={websiteRoutes.gameHistory}
                active={isActivePath(websiteRoutes.gameHistory)}
            />
        </>}
    </Box>
}