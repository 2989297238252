import {FC} from "react";
import {Box, Stack, Typography, useTheme} from "@mui/material";
import {Link} from "react-router-dom";

export const SubCategoryItem: FC<{link: string, title: string, picUrl: string, active?: boolean}> = ({link, title, picUrl, active = false}) => {
    const theme = useTheme()

    return <Box
        // flex={max1280 ? 1 : undefined}
        sx={{
            '& a': {
                textDecoration: 'none',
                color: theme.palette.text.primary },
        }}
    >
        <Link to={link}>
            <Stack
                minWidth={'120px'}
                alignItems={'center'}
                gap={1}
                justifyContent={'center'}
                sx={active ? ({
                    backgroundColor: 'white',
                    boxShadow: 'inset 0 0 10px #000',
                    color: theme.palette.background.default
                }) : {cursor: 'pointer'}}
                py={1}
                borderRadius={'8px'}
            >
                <Box
                    width={'24px'}
                    height={'24px'}
                    sx={{
                        backgroundImage: `url(${picUrl})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat'
                    }}
                />
                <Typography fontSize={16} textAlign={'center'}>
                    {title}
                </Typography>
            </Stack>
        </Link>
    </Box>
}