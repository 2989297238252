import {FC} from "react";
import { CasinoGridView } from "./CasinoGridView";
import ProvidersIcon from '../../../../assets/subcategory_icons/providers.svg'
import {Link} from "react-router-dom";
import {Box, Button, useTheme} from "@mui/material";
import {websiteRoutes} from "../../../features/routes/routes";
import {usePlayGames} from "../../../features/play_games/usePlayGames";
import {getMediaCDN} from "../../../config";
import {useCommonMediaQueries} from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { ProviderCard } from "./ProviderCard";
import {useLanguage} from "../../../features/localisation/useLanguage";

export const ProvidersGrid: FC = () => {
    const { languagePack: {pack: {common: langCommon, subcategories: langSub}}} = useLanguage()
    const theme = useTheme()
    const gap = theme.spacing(1)
    const itemSize = `calc((100% - ${gap} * (8 - 1)) / 8)`
    const cdn = getMediaCDN()
    const { mediumScreen: md} = useCommonMediaQueries()

    const {providers} = usePlayGames()


    return <CasinoGridView
        picUrl={ProvidersIcon}
        title={langSub.providers}
        actions={<>
            <Link to={websiteRoutes.providers}>
                <Button variant={'outlined'} size={'large'}>
                    {langCommon.viewAllBTN}
                </Button>
            </Link>
        </>}
    >
        <Box
            sx={{
                overflowX: 'auto',
            }}
        >
            <Box
                width={md ? `calc(840px + ${gap} * 7)` : undefined}
                display={'flex'}
                gap={gap}
                flexWrap={'wrap'}
            >
                {providers && providers.slice(0, 16).map(provider => {
                    return <ProviderCard
                        key={provider.provider_name}
                        providerLink={`${websiteRoutes.casino}?provider=${provider.provider_name}`}
                        id={provider.provider_name}
                        itemSize={itemSize}
                        md={md}
                        // picUrl={`${cdn}/provider_img/hor/${provider.provider_name}.png`}
                        picUrl={`${cdn}/provider_img/hor/${provider.logo}`}
                    />
                })}
            </Box>
        </Box>
    </CasinoGridView>
}