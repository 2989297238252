import {FC} from "react";
import {Box} from "@mui/material";
import {NavigatorBottom} from "./navigator_bottom/NavigatorBottom";

export const BottomMobileMenu: FC<{dashboardMode?: boolean}> = ({dashboardMode = false}) => {
    return <Box
        sx={theme => ({
            backgroundColor: '#311e0c'
        })}
        height={'100%'}
    >
        <NavigatorBottom dashboardMode={dashboardMode}/>
    </Box>
}