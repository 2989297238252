import React, {FC, useEffect} from "react";
import {Page} from "../components/layout/Page";
import {useGamesHistoryWatcher} from "../features/gameHistory/useGamesHistoryWatcher";
import {useGamesHistory} from "../features/gameHistory/useGamesHistory";
import {useUser} from "../features/user/useUser";
import {useCommonMediaQueries} from "../features/common_funcs/mediaQueries/useCommonMediaQueries";
import { Stack, Typography } from "@mui/material";
import {PageSizeSwitcher} from "../components/common/page_size_switcher/PageSizeSwitcher";
import {Paginator} from "../components/common/paginator/Paginator";
import {GamesHistoryItemCard} from "../components/games_history/GamesHistoryItemCard";
import {GameHistoryTable} from "../components/games_history/GameHistoryTable";
import {LoginFormModal} from "../components/authorization/login/LoginForm";
import {useLanguage} from "../features/localisation/useLanguage";

export const GameHistoryPage: FC = () => {
    const { languagePack: {pack: {navigation: lang}}} = useLanguage()
    useGamesHistoryWatcher()
    const { pageSize, currentPage, totalPages, clearGameHistory, gameHistory } = useGamesHistory()
    const {user, token} = useUser()
    const { mediumScreen } = useCommonMediaQueries()

    useEffect(() => {
        return () => {
            clearGameHistory()
        }
    }, [ clearGameHistory ])

    return <Page dashboardMode>
        <Stack
            maxWidth={'1280px'}
            width={'100%'}
            mx={'auto'}
            p={2}
            gap={2}
        >
            {user && token && <Stack padding={'16px'} gap={'12px'}>
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <Typography fontSize={'28px'}>
                        {lang.gamesHistory}
                    </Typography>
                    <PageSizeSwitcher pageSize={pageSize}/>
                </Stack>
                {mediumScreen ? (gameHistory && gameHistory.map(game => <GamesHistoryItemCard
                        currency={user?.user_currency}
                        key={game.uuid}
                        gameHistoryItem={game}/>)) :
                    <GameHistoryTable/>}
                <Stack direction={'row'} justifyContent={'end'}>
                    <Paginator totalPages={totalPages} currentPage={currentPage}/>
                </Stack>
            </Stack>}
        </Stack>

        {user === null && <LoginFormModal/>}
    </Page>
}