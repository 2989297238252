import { SvgIcon, SvgIconProps } from '@mui/material';
import { FC } from "react";

const EighteenPlusIcon: FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path stroke={'currentColor'} fill={'none'} strokeWidth={2} strokeLinecap={'round'} strokeLinejoin={'round'} strokeMiterlimit={10} d="M20.1,10v4.3"/>
                <path stroke={'currentColor'} fill={'none'} strokeWidth={2} strokeLinecap={'round'} strokeLinejoin={'round'} strokeMiterlimit={10}  d="M17.9,12.2h4.3"/>
                <path stroke={'currentColor'} fill={'none'} strokeWidth={2} strokeLinecap={'round'} strokeLinejoin={'round'} strokeMiterlimit={10}
                      d="M20.5,17.8c-1.8,2.7-5,4.5-8.5,4.5C6.3,22.3,1.7,17.7,1.7,12S6.3,1.7,12,1.7c3.6,0,6.8,1.8,8.6,4.6"/>
                <path stroke={'currentColor'} fill={'none'} strokeWidth={2} strokeLinecap={'round'} strokeLinejoin={'round'} strokeMiterlimit={10}  d="M7.4,15.3V8.8L5.7,10"/>
                <path stroke={'currentColor'} fill={'none'} strokeWidth={2} strokeLinecap={'round'} strokeLinejoin={'round'} strokeMiterlimit={10}
                      d="M10.8,13.5c0-1,0.8-1.7,1.7-1.7s1.7,0.8,1.7,1.7s-0.8,1.7-1.7,1.7S10.8,14.5,10.8,13.5z"/>
                <path stroke={'currentColor'} fill={'none'} strokeWidth={2} strokeLinecap={'round'} strokeLinejoin={'round'} strokeMiterlimit={10}
                      d="M11,10.3c0-0.8,0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5s-0.7,1.5-1.5,1.5S11,11.1,11,10.3z"/>
            </svg>
        </SvgIcon>
    );
}

export default EighteenPlusIcon;