import {Box, Stack, Typography, useMediaQuery, useTheme} from "@mui/material";
import {FC, useState} from "react";
import SearchIcon from '@mui/icons-material/Search';
import {Items} from "./Items";
import {useLanguage} from "../../../features/localisation/useLanguage";
import {SearchGameModal} from "../../search_game/SearchGameModal";

export const SubcategoryPanel: FC = () => {
    const {languagePack: {pack: {common: lang}}} = useLanguage()
    const theme = useTheme()
    const max1280 = useMediaQuery(theme.breakpoints.up(1280));

    const [isSearchOpened, setSearchOpened] = useState<boolean>(false)

    return <Box>
        <Stack
            maxWidth={'1280px'}
            mx={'auto'}
            p={1}
        >
            <Box
                display={'flex'}
                alignItems={'center'}
                gap={2}
                py={2}
                overflow={!max1280 ? 'auto' : undefined}
            >
                <Items/>
            </Box>
            <Box
                onClick={() => setSearchOpened(true)}
                borderRadius={'8px'}
                sx={{
                    backgroundColor: 'white',
                    color: theme.palette.background.default,
                    cursor: 'pointer',
                    userSelect: 'none'
                }}
                p={1}
                display={'flex'}
                alignItems={'center'}
                gap={0.5}
            >
                <SearchIcon fontSize={'small'}/>
                <Typography fontSize={14}>
                    {lang.searchPlaceholder}
                </Typography>
            </Box>
        </Stack>

        {isSearchOpened && <SearchGameModal close={() => setSearchOpened(false)}/>}
    </Box>
}