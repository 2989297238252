import {FC, ReactNode} from "react";
import {Stack, useTheme} from "@mui/material";

export const PageContent: FC<{children: ReactNode}> = ({children}) => {
    const theme = useTheme()

    return <Stack
        maxWidth={'1280px'}
        width={'100%'}
        my={3}
        mx={'auto'}
        p={2}
        // gap={2}
        borderRadius={'8px'}
        sx={{
            backgroundColor: 'white',
            color: theme.palette.background.default,
            '& h1': {
                my: 0
            },
            '& p': {
                my: 1
            }
        }}
    >
        {children}
    </Stack>
}