import {FC} from "react";
import {Box, useTheme} from "@mui/material";
import {LinkCard} from "./LinkCard";
import {websiteRoutes} from "../../../features/routes/routes";
import SportsCover from '../../../../assets/top_links_cover/sports.png'
import EvolutionCover from '../../../../assets/top_links_cover/evolution.png'
import LiveCasinoCover from '../../../../assets/top_links_cover/live_casino.png'
import LiveSportsCover from '../../../../assets/top_links_cover/live_sports.png'
import PragmaticCover from '../../../../assets/top_links_cover/pragmatic_play.png'
import CasinoCover from '../../../../assets/top_links_cover/casino.png'
import {useCommonMediaQueries} from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {useLanguage} from "../../../features/localisation/useLanguage";

export const TopLinksCards: FC = () => {
    const { languagePack: { pack: { topLinks: lang }}} = useLanguage()
    const theme = useTheme()
    const {mediumScreen: md} = useCommonMediaQueries()

    const gap = theme.spacing(1)
    const cardsCount = 6
    const padding = md ? 0 : theme.spacing(2)
    const width = md ? '100dvw' : `calc(((100dvw - ${cardsCount - 1} * ${gap}) / ${cardsCount}) - ${padding})`

    return <Box
        flexDirection={md ? 'column' : 'row'}
        display={'flex'}
        justifyContent={'space-between'}
        gap={gap}
        p={padding}
    >
        <LinkCard
            size={width}
            title={lang.sports}
            link={websiteRoutes.sports}
            picUrl={SportsCover}
        />
        <LinkCard
            size={width}
            title={lang.liveSports}
            link={websiteRoutes.liveSports}
            picUrl={LiveSportsCover}
        />
        <LinkCard
            size={width}
            title={lang.casino}
            link={websiteRoutes.casino}
            picUrl={CasinoCover}
        />
        <LinkCard
            size={width}
            title={lang.liveCasino}
            link={websiteRoutes.liveCasino}
            picUrl={LiveCasinoCover}
        />
        <LinkCard
            size={width}
            title={lang.pragmatic}
            link={websiteRoutes.pragmatic}
            picUrl={PragmaticCover}
        />
        <LinkCard
            size={width}
            title={lang.evolution}
            link={websiteRoutes.evolution}
            picUrl={EvolutionCover}
        />
    </Box>
}