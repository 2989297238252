import React, { useState } from "react";
import {
    AppBar,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Toolbar,
    Typography, useTheme
} from "@mui/material";
import { ModalInterface } from "./model";

import CloseIcon from '@mui/icons-material/Close';

interface ModalWrapperProps<T> {
    component: React.ComponentType<T>;
    componentProps: T;
    maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
    backgroundColor?: string;
    fullScreen?: boolean;
    contentPadding?: string
}

export const ModalWrapper  = <T extends ModalInterface>({ component: Component, componentProps, ...props }: ModalWrapperProps<T>) => {
    const { dialogTitle } = componentProps
    const theme = useTheme()
    const { close } = componentProps
    const [ opened, setOpened ] = useState(true)
    const pendingClose = () => {
        setOpened(false);
        setTimeout(() => close?.(), 500)
    }

    return <Dialog
        fullScreen={props.fullScreen}
        sx={{
            backdropFilter: 'blur(5px)',
            '& .MuiDialog-paper': {
                backgroundColor: props.backgroundColor ?? theme.palette.primary.main,
                borderRadius: '16px'
            },
            '& .MuiDialogContent-root': {
                p: props.contentPadding,
                color: 'black',
            },
            '& .MuiDialogTitle-root, .MuiDialogActions-root': {
                color: 'black'
            }
        }}
        maxWidth={ props.maxWidth ?? 'xs' }
        fullWidth
        open={opened}
        onClose={pendingClose}
    >
        {props.fullScreen && <AppBar sx={{position: 'relative', borderRadius: 0}}>
            <Toolbar>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={pendingClose}
                    aria-label="close"
                >
                    <CloseIcon/>
                </IconButton>
                {dialogTitle && <Typography sx={{ml: 2}} variant="h6" component="div">
                    {dialogTitle}
                </Typography>}
            </Toolbar>
        </AppBar>}
        {dialogTitle && !props.fullScreen && <DialogTitle>{dialogTitle}</DialogTitle>}
        <DialogContent>
            <Component {...componentProps} onDone={pendingClose}/>
        </DialogContent>
    </Dialog>
}