import React, {FC} from "react";
import {Carousel} from "react-responsive-carousel";
import {Box} from "@mui/material";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {CarouselSliderProps} from "./CarouselSlider.interface"; // requires a loader
import './CarouselSlider.css'
import {useCommonMediaQueries} from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {Link} from "react-router-dom";

export const CarouselSlider: FC<CarouselSliderProps> = ({picUrls}) => {
    const [selectedSlide, setSelectedSlide] = React.useState(0);
    const { mediumScreen: md } = useCommonMediaQueries()

    return <Box
        dir={'ltr'}
        width={'100%'}
        overflow={'hidden'}
        sx={{
            position: 'relative',
            '&::before': {
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundImage: `url(${picUrls[selectedSlide].pic})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                filter: 'blur(20px)',
                zIndex: -1,
            },
        }}
    >
        <Carousel
            showStatus={false}
            showThumbs={false}
            autoPlay={true}
            interval={5000}
            centerMode={!md}
            centerSlidePercentage={!md ? 50 : 100}
            infiniteLoop
            onChange={index => setSelectedSlide(index)}
            axis={'horizontal'}
        >
            {picUrls.map((url, index) => (
                <Box
                    height={md ? '60vw' : '35vw'}
                    width={'100%'}
                    p={md ? 0 : 3}
                    py={md ? 0 : 4}
                >
                    <Link to={url.link}>
                    <Box
                        sx={{
                            cursor: 'pointer',
                            backgroundImage: `url(${url.pic})`,
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            filter: index !== selectedSlide ? 'brightness(0.2)' : 'brightness(1)',
                            transition: 'filter 0.6s ease-in-out',
                        }}
                        width={'100%'}
                        height={'100%'}
                    />
                    </Link>
                </Box>
            ))}
        </Carousel>
    </Box>
}