import {FC} from "react";
import {Box, useTheme} from "@mui/material";
import { Logo } from "../Logo";
import {useCommonMediaQueries} from "../../../features/common_funcs/mediaQueries/useCommonMediaQueries";
import {NavigatorTop} from "../navigator_top/NavigatorTop";
import { Link } from "react-router-dom";
import {LoginActivator} from "../../authorization/login/LoginActivator";
import {LogoutActivator} from "../../authorization/logout/LogoutActivator";
import {UserNameTitle} from "./UserNameTitle";
import { ShowBalance } from "../../balance/ShowBalance";
import { useUser } from "../../../features/user/useUser";
import LanguageSwitcher from "../../language_switcher/LanguageSwitcher";

export const Header: FC<{dashboardMode?: boolean}> = ({dashboardMode = false}) => {
    const {mediumScreen: md} = useCommonMediaQueries()
    const theme = useTheme()
    const { user } = useUser()

    return <Box
        p={theme.spacing(1, 2)}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
    >
        <Link to={'/'}><Logo height={md ? '32px' : '60px'}/></Link>
        {!md && <NavigatorTop dashboardMode={dashboardMode}/>}
        <Box
            display={'flex'}
            alignItems={'center'}
            gap={2}
        >
            {user && <ShowBalance balance={user.user_balance} currency={'TND'}/>}
            <UserNameTitle/>
            <LoginActivator/>
            <LogoutActivator/>
            {!md && <LanguageSwitcher/>}
        </Box>
    </Box>
}